export const getUser = async ({ accessToken }) => {
    const response = await fetch("/user", {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    const json = await response.json();
    if (json.error) {
        throw new Error(json.message);
    }
    return json;
};

export const submitAnswer = async ({
    accessToken,
    rank,
    solutionCRC,
    solved,
    missionLog
}) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
    };
    if (!accessToken) {
        delete headers.Authorization;
    }
    const response = await fetch("/user", {
        method: "POST",
        headers,
        body: JSON.stringify({ rank, solved, solutionCRC, missionLog })
    });

    const json = await response.json();
    if (json.error) {
        throw new Error(json.message);
    }
    return json;
};

export const submitTestSolution = async (accessToken, testSolution) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
    };
    if (!accessToken) {
        delete headers.Authorization;
    }
    const response = await fetch("/user", {
        method: "POST",
        headers,
        body: JSON.stringify({ testSolution })
    });

    const json = await response.json();
    if (json.error) {
        throw new Error(json.message);
    }
    return json;
};
