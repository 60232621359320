import { setLoggedInUser } from "./interfaces/auth0_client";

// TODO MUST BE FALSE!!!
const BYPASS_PAYPAL = false;

const userEmail = document.querySelector("#user-email");
const userPassword = document.querySelector("#user-password");
const acceptTerms = document.querySelector("#accept-terms");
const emailErrorMessage = document.querySelector(
    ".buy-email-step .error-message"
);
const passwordErrorMessage = document.querySelector(
    ".buy-password-step .error-message"
);
const emailForm = document.querySelector(".buy-email-step form");
const passwordForm = document.querySelector(".buy-password-step form");
const showBuyPayPalStep = document.querySelector("#show-buy-paypal-step");
const showBuyIntroStep = document.querySelector("#show-buy-intro-step");
const showBuyPasswordStep = document.querySelector("#show-buy-password-step");
const showBuyThankYouStep = document.querySelector("#show-buy-thank-you-step");
const showBuyErrorStep = document.querySelector("#show-buy-error-step");
const paymentErrorMessage = document.querySelector(
    ".buy-error-step .error-message"
);
const buySolvedCases = document.querySelector("#buy-solved-cases");
const showNoSendingModal = document.querySelector("#show-no-sending-modal");

const validateEmail = () => {
    const email = (userEmail.value || "").trim().toLowerCase();
    if (!email) {
        return "Please enter an email address";
    }

    if (!email.includes("@")) {
        return "Email should include an @";
    }
    if (!email.substr(email.indexOf("@")).includes(".")) {
        return "Invalid email. Please recheck.";
    }
};

const validateTerms = () => {
    const accept = acceptTerms.checked;
    if (!accept) {
        return "Please accept the terms to continue";
    }
};

const validatePassword = () => {
    // Password strength for auth0 is set to Good
    // According to https://auth0.com/docs/connections/database/password-strength
    // this means it should be at least 8 characters including a lower-case letter,
    // an upper-case letter, and a number. (a special key can replace one of the
    // groups, but we'll stick to these, effectively level Fair).
    // We have to validate password strength to prevent adding user to auth0 from
    // failing after the payment.

    const password = (userPassword.value || "").trim();
    if (!password) {
        return "Please choose a password";
    }
    if (password.length < 8) {
        return "Password must have at least 8 characters";
    }
    if (!password.split("").some((c) => c >= "a" && c <= "z")) {
        return "Password must contain a lowercase letter";
    }
    if (!password.split("").some((c) => c >= "A" && c <= "Z")) {
        return "Password must contain an uppercase letter";
    }
    if (!password.split("").some((c) => c >= "0" && c <= "9")) {
        return "Password must contain a number";
    }
};

const validateEmailForm = (e) => {
    const errorMessage = validateEmail() || validateTerms();
    emailErrorMessage.innerHTML = errorMessage || "";
    if (!errorMessage) {
        showBuyPasswordStep.checked = true;
    }
    e.preventDefault();
    return false;
};

emailForm.addEventListener("submit", validateEmailForm);

const validatePasswordForm = (e) => {
    const errorMessage = validatePassword();
    passwordErrorMessage.innerHTML = errorMessage || "";
    if (!errorMessage) {
        showBuyPayPalStep.checked = true;
    }
    e.preventDefault();
    return false;
};

passwordForm.addEventListener("submit", validatePasswordForm);

const thankYouAndLogin = (
    email,
    { error, message, order_id, capture_id, access_token, expiration }
) => {
    if (error) {
        paymentErrorMessage.innerHTML = message;
        showBuyErrorStep.checked = true;
        return;
    }

    document.querySelector(".purchase-email").innerHTML = email;
    document.querySelector(".purchase-orderid").innerHTML = order_id;
    document.querySelector(".purchase-captureid").innerHTML = capture_id;
    document.querySelector(".purchase-expiration").innerHTML = new Date(
        expiration
    ).toDateString();
    showBuyThankYouStep.checked = true;
    showNoSendingModal.checked = true; // Remove the end of free trial screen

    setLoggedInUser(email, access_token);
};

const initialisePaypal = () => {
    if (initialisePaypal.alreadyRun) {
        return;
    }
    initialisePaypal.alreadyRun = true;

    if (BYPASS_PAYPAL) {
        document.querySelector("#paypal-button-container").innerHTML = `
            <button class="button">Bypass PayPal</button>
        `;
        document
            .querySelector("#paypal-button-container button")
            .addEventListener("click", () => {
                const email = (userEmail.value || "").trim().toLowerCase();
                const password = (userPassword.value || "").trim();

                return fetch("/activate", {
                    method: "POST",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({
                        email,
                        password
                    })
                })
                    .then((res) => res.json())
                    .then(thankYouAndLogin.bind(null, email));
            });

        return;
    }

    const paypalScript = document.createElement("script");
    paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${
        PAYPAL_CLIENT_ID
    }`;
    paypalScript.onload = () => {
        paypal
            .Buttons({
                // TODO optional update of rank and number of cases solved if less then current
                createOrder: function () {
                    return fetch("/buy", {
                        method: "POST",
                        headers: {
                            "content-type": "application/json"
                        },
                        body: JSON.stringify({
                            email: (userEmail.value || "").trim().toLowerCase(),
                            solved: (buySolvedCases.value || "").trim()
                        })
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            if (data.error) {
                                throw new Error(data.message);
                            }
                            return data.orderID;
                        });
                },

                onApprove: function (data, actions) {
                    // TODO When on Server: Funding failure
                    // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                    const email = (userEmail.value || "").trim().toLowerCase();
                    const password = (userPassword.value || "").trim();

                    return fetch("/activate", {
                        method: "POST",
                        headers: {
                            "content-type": "application/json"
                        },
                        body: JSON.stringify({
                            orderId: data.orderID,
                            email,
                            password
                        })
                    })
                        .then((res) => res.json())
                        .then((json) => {
                            if (json.error === "INSTRUMENT_DECLINED") {
                                return actions.restart();
                            }
                            return json;
                        })
                        .then(thankYouAndLogin.bind(null, email));
                },

                onError: function (error) {
                    console.log("ON ERROR", error);
                    paymentErrorMessage.innerHTML = error.message;
                    showBuyErrorStep.checked = true;
                }
            })
            .render("#paypal-button-container");
    };

    document.body.append(paypalScript);
};

document
    .querySelector(".buy-intro-step label")
    .addEventListener("click", initialisePaypal);

document
    .querySelector(".buy-a-license-overlay .close-dialog")
    .addEventListener("click", () => {
        // The uncheck to unshow the overlay is in the for attribute
        // Make sure we reset back to the first step
        showBuyIntroStep.checked = true;
    });

document
    .querySelector(".buy-thank-you-step label[for='show-buy-a-license']")
    .addEventListener("click", () => {
        // The uncheck to unshow the overlay is in the for attribute
        // Make sure we go to Brief after thank you
        document.querySelector("#show-workspace-section").checked = true;
    });
