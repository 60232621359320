import {
  join,
  filter,
  groupBy,
  having,
  project,
  distinct,
  orderBy,
  limit,
  toERD,
  toHTML,
  numberOfRows,
} from './table.js';
import { parse } from './parser.js';

const lastCommaToAnd = str =>
  str
    // A lazy hack...
    // Instead of repalcing the last ,
    // reverse, change the first one then reverse again...
    .split('')
    .reverse()
    .join('')
    .replace(' ,', ' dna ')
    .split('')
    .reverse()
    .join('');

const getTable = (db, tableName) => {
  const dbTable = db
    .filter(t => t.name.toLowerCase() === tableName.toLowerCase())
    .shift();
  if (!dbTable) {
    const errorMessage = `Table "${tableName}" does not exist.\n${
      db.length > 1
        ? `Available tables are: ${lastCommaToAnd(
            db.map(t => t.name).join(', '),
          )}`
        : `Use "${db[0].name}" table for this mission`
    }`;
    // In the mobile version the user doesn't control the case anyway
    // .\nCase does not matter.`;
    throw new Error(errorMessage);
  }
  return dbTable;
};

const calculateFrom = (db, fromTree) => {
  if (fromTree.table) {
    return getTable(db, fromTree.table);
  }

  const left = calculateFrom(db, fromTree.left);
  const right = calculateFrom(db, fromTree.right);

  if (fromTree.join !== 'inner') {
    throw new Error('Currently only inner join is supported');
  }

  return join(fromTree.join, left, right, fromTree.on);

  throw new Error(left.name + ' ' + fromTree.join + ' ' + right.name);
};

export function execute(db, sql) {
  const ast = parse(sql);
  let table = {
    headers: [],
    data: [],
  };
  if (sql.trim() === '') {
    return table;
  }
  if (ast.from) {
    table = calculateFrom(db, ast.from);
  }
  if (ast.where) {
    table = filter(table, ast.where);
  }
  if (ast.groupBy) {
    table = groupBy(table, ast.groupBy);
  }
  if (ast.having) {
    table = having(table, ast.having);
  }
  table = project(table, ast.select);
  if (ast.distinct) {
    table = distinct(table);
  }
  if (ast.orderBy) {
    table = orderBy(table, ast.orderBy);
  }
  if (ast.limit) {
    table = limit(table, ast.limit);
  }
  return table;
}

export function tableERD(db, tableName, withShortcuts = true) {
  const table = getTable(db, tableName);
  return toERD(table, withShortcuts);
}

const extractTagFromString = (str, tagName) =>
  str.substring(
    str.indexOf(`<${tagName}>`) + tagName.length + 2,
    str.lastIndexOf(`</${tagName}>`),
  );

export function resultsHTML(table) {
  const html = toHTML(table);
  return {
    thead: extractTagFromString(html, 'thead'),
    tbody: extractTagFromString(html, 'tbody'),
  };
}

export function isEmpty(table) {
  return numberOfRows(table) === 0;
}
