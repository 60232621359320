const delimiters = [' ', '\n', '\t'];
const lengthTwoReserved = ['<=', '>=', '<>', '!='];
const lengthOneReserved = ['<', '>', '=', ')', '(', ',', '*', ';'];
const reservedTokens = [...lengthOneReserved, ...lengthTwoReserved];
const multiWordTokens = [
  'order by',
  'group by',
  'is null',
  'is not null',
  'not in',
  'inner join',
  'not between',
];
const caseinsensitiveTokens = [
  ...multiWordTokens,
  'and',
  'or',
  'in',
  'desc',
  'asc',
  'as',
  'on',
  'between',
];

const combineMultiWordTokens = tokens =>
  tokens.reduce((acc, t) => {
    acc.push(t);
    multiWordTokens.forEach(mwt => {
      const numberOfWords = mwt.split(' ').length;
      const lastTokens = acc.slice(-numberOfWords);
      if (!lastTokens.every(t => t.type === 'token')) {
        // shouldn't be a string
        return;
      }
      if (lastTokens.map(t => t.value.toLowerCase()).join(' ') === mwt) {
        acc = acc.slice(0, acc.length - numberOfWords);
        acc.push({
          type: 'token',
          value: lastTokens.map(t => t.value).join(' '),
        });
      }
    });
    return acc;
  }, []);

const caseinsensitiveTokensToLower = tokens =>
  tokens.map(token => {
    const { type, value } = token;
    const match =
      type === 'token' &&
      caseinsensitiveTokens.reduce(
        (acc, cit) => acc || cit.toLowerCase() === value.toLowerCase(),
        false,
      );
    return match ? { ...token, value: token.value.toLowerCase() } : token;
  });

/**
 * Tokenize str into an array of tokens.
 * Each token has a type and value.
 * Token types are: string, number, reserved, or token.
 */
export function tokenize(str) {
  // Add a final delimiter
  const chars = (str + ' ').split('');
  let stringMarker = '';
  let isEscaped = false;
  let curToken = '';
  let bracketLevel = 0;
  const tokens = [];

  chars.forEach((c, index) => {
    // String and escaping handling
    if ((c === '"' || c === "'") && !stringMarker && !isEscaped) {
      stringMarker = c;
      return;
    }
    if (stringMarker && !isEscaped && c === stringMarker) {
      stringMarker = '';
      tokens.push({
        type: 'string',
        value: curToken,
      });
      curToken = '';
      return;
    }
    if (!isEscaped && c === '\\') {
      isEscaped = true;
      return;
    }
    if (isEscaped) {
      isEscaped = false;
    }
    if (stringMarker) {
      curToken += c;
      return;
    }
    // End of string handling

    // Close last token
    if (
      (delimiters.includes(c) ||
        // Special case: ! as beginning of != is not a lengthOneReserved
        c === '!' ||
        (lengthOneReserved.includes(c) &&
          !lengthTwoReserved.includes(curToken + c))) &&
      curToken !== ''
    ) {
      if (isNaN(curToken)) {
        tokens.push({
          type: 'token',
          value: curToken,
        });
      } else {
        tokens.push({
          type: 'number',
          value: Number(curToken),
        });
      }
      curToken = '';
    }

    // Ignore spaces
    if (delimiters.includes(c)) {
      return;
    }

    curToken += c;

    if (
      index + 1 < chars.length &&
      lengthTwoReserved.includes(c + chars[index + 1])
    ) {
      // Don't add '<' instead of '<=' wait to next round
      return;
    }

    if (reservedTokens.includes(curToken)) {
      if (curToken === '(') {
        bracketLevel++;
      }
      if (curToken === ')') {
        bracketLevel--;
        if (bracketLevel < 0) {
          throw new Error('Closing bracket without an opening match');
        }
      }
      tokens.push({
        type: 'reserved',
        value: curToken.toLowerCase(),
      });
      curToken = '';
      return;
    }
  });

  if (stringMarker) {
    throw new Error('Untermintated string');
  }

  if (bracketLevel > 0) {
    throw new Error('Unclosed open bracket');
  }

  return caseinsensitiveTokensToLower(combineMultiWordTokens(tokens));
}
