const query = document.querySelector(".query");
const placeholder = document.querySelector(".placeholder");

export default () => {
    const parts = query.querySelectorAll(".query-part");
    if (!parts.length && !query.querySelector(".placeholder")) {
        // Show placeholder if nothing selected
        query.appendChild(placeholder);
    }
    if (parts.length && query.querySelector(".placeholder")) {
        // Remove it when there are query parts
        query.removeChild(placeholder);
    }

    let withinIn = false;

    for (let i = 0; i < parts.length; ++i) {
        const current = parts[i];
        const next = parts[i + 1];
        const prev = parts[i - 1];
        const currentType = current.getAttribute("data-type");
        const nextType = next ? next.getAttribute("data-type") : undefined;
        let currentText = current.innerHTML.trim();
        let prefix = "";
        let postfix = "";
        const nextText = next ? next.innerHTML.trim() : undefined;
        const prevText = prev ? prev.innerHTML.trim() : undefined;
        const spaceUnlessFollowedBySemicolon =
            nextType === "semicolon" ? "" : " ";

        // Remove all dots, comma's and brackets automatically added
        if (
            (currentText.endsWith(",") ||
                currentText.endsWith(".") ||
                currentText.endsWith(")")) &&
            currentText !== "." &&
            currentText !== "," &&
            currentText !== ")"
        ) {
            currentText = currentText.substr(0, currentText.length - 1);
        }
        if (currentText[0] === "(" && currentText !== "(") {
            currentText = currentText.substr(1);
        }
        if (currentText.endsWith(" AND")) {
            // Automatic AND added to the first value after BETWEEN
            currentText = currentText.substr(0, currentText.length - 4);
        }

        if (
            (currentType === "field" ||
                currentType === "value" ||
                currentType === "sort-order" ||
                currentText === "*") &&
            (nextType === "field" ||
                nextType === "table" ||
                nextType === "value" ||
                nextText === "*")
        ) {
            // Add commas between field names
            postfix = ",";
        }
        if (
            currentType === "table" &&
            (nextType === "field" || nextText === "*")
        ) {
            // Add a dot between a table and a field name
            postfix = ".";
        }

        // After identifying an IN operator, all subsequent field, value or table
        // should be enclosed in ( ).
        if (withinIn) {
            if (currentType === "value" && nextType !== "value") {
                // Last arg postfixed with ) plus remove last comma
                postfix = ")";
            }

            if (currentType != "value") {
                withinIn = false;
            } else {
                if (prevText === "IN") {
                    // First arg preceded with (
                    prefix = "(";
                }
            }
        } else if (currentText === "IN") {
            withinIn = true;
        }

        // Add AND to the end of the first value after BETWEEN
        if (prevText === "BETWEEN") {
            postfix = " AND";
        }

        current.innerHTML =
            prefix +
            currentText +
            postfix +
            (postfix === "." ? "" : spaceUnlessFollowedBySemicolon);
    }

    // Convert < or > followed by = to <= or >=
    // Convert < followed by > to <>
    for (let i = 0; i < parts.length; ++i) {
        const current = parts[i];
        const prev = parts[i - 1];
        let currentText = current.innerHTML.trim();
        const prevText = prev ? prev.innerHTML.trim() : undefined;

        if (
            (prevText === "&lt;" || prevText === "&gt;") &&
            currentText === "="
        ) {
            prev.innerText = prev.innerText.trim() + "= ";
            query.removeChild(current);
        } else if (prevText === "&lt;" && currentText === "&gt;") {
            prev.innerText = "<> ";
            query.removeChild(current);
        }
    }
};
