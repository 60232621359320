import {
    oneOf,
    choose,
    number,
    animal,
    generateTable
} from "./generator/generator";

import {
    project,
    distinct,
    headerContentPlural,
    headerContentSingle,
    filter,
    orderBy,
    limit,
    numberOfRows,
    addRowNumbers,
    extractRowNumbers,
    removeRowNumbers,
    values
} from "./sql/table";

const DEFAULT_NUMBER_OF_ROWS = 300;

const FIRST_NAME_LAST_NAME = [
    {
        temporary: true,
        type: "gender"
    },
    {
        type: "first name",
        dependOn: [-1]
    },
    {
        type: "last name",
        dependOn: [-2]
    }
];

const FULL_NAME = [
    {
        temporary: true,
        type: "gender"
    },
    {
        type: "full name",
        dependOn: [-1]
    }
];

const tables = {
    people: {
        users: () => {
            const table = generateTable({
                name: "users",
                columns: [
                    ...FIRST_NAME_LAST_NAME,
                    {
                        type: "email",
                        dependOn: [-1, -2]
                    },
                    {
                        name: ["LastAccess", "AccessTime"],
                        single: ["last access", "access time"],
                        plural: ["last access times", "access times"],
                        type: "timestamp",
                        daysBack: 60
                    },
                    oneOf([
                        {
                            name: ["Posts", "NumberOfPosts"],
                            single: ["number of posts", "number of posts"],
                            plural: ["number of posts", "number of posts"],
                            type: "number",
                            min: 0,
                            max: 100
                        },
                        {
                            name: ["Downloads", "NumberOfDownloads"],
                            single: [
                                "number of downloads",
                                "number of downloads"
                            ],
                            plural: [
                                "number of downloads",
                                "number of downloads"
                            ],
                            type: "number",
                            min: 0,
                            max: 100
                        }
                    ])
                ],
                numberOfRows: DEFAULT_NUMBER_OF_ROWS,
                rules: {}
            });

            return {
                type: "whole-table",
                db: [table],
                values: [],
                brief: [
                    "An illegal site's servers were seized in a recent operation."
                ]
            };
        },
        subscribers: () => {
            const name = oneOf(["subscribers", "members"]);
            const table = generateTable({
                name,
                columns: [
                    ...FULL_NAME,
                    {
                        type: "username",
                        dependOn: [-1],
                        finalIndex: 0,
                        unique: true
                        // Too soon, no need for this on whole table missions
                        // isPrimaryKey: true,
                    },
                    {
                        type: "passwordHash"
                    },
                    {
                        name:
                            name === "subscribers"
                                ? ["SubscribedSince", "SubscriptionDate"]
                                : ["MemberSince", "JoinedOn"],
                        single:
                            name === "subscribers"
                                ? ["subscribed since date", "subscription date"]
                                : ["member since date", "joined on date"],
                        plural:
                            name === "subscribers"
                                ? [
                                      "subscribed since dates",
                                      "subscription dates"
                                  ]
                                : ["member since dates", "joined on dates"],
                        type: "date",
                        daysBack: 365 * 2
                    },
                    {
                        name: ["Address", "MailingAddress"],
                        single: ["address", "mailing address"],
                        plural: ["addresses", "mailing addresses"],
                        type: "fullAddress"
                    },
                    oneOf([
                        {
                            name: ["Purchases", "NumberOfPurchases"],
                            single: [
                                "number of purchases",
                                "number of purchases"
                            ],
                            plural: [
                                "number of purchases",
                                "number of purchases"
                            ],
                            type: "number",
                            min: 0,
                            max: 50
                        },
                        {
                            name: ["Comments", "NumberOfComments"],
                            single: [
                                "number of comments",
                                "number of comments"
                            ],
                            plural: [
                                "number of comments",
                                "number of comments"
                            ],
                            type: "number",
                            min: 0,
                            max: 200
                        }
                    ])
                ],
                numberOfRows: DEFAULT_NUMBER_OF_ROWS,
                rules: {}
            });

            return {
                type: "whole-table",
                db: [table],
                values: [],
                brief: [
                    oneOf([
                        "A hacked site " +
                            name +
                            "' details have surfaced on a darknet forum.",
                        "White hat hacker has sent SQLPD exposed " +
                            name +
                            "' details of a shady site connected to various persons of interest."
                    ])
                ]
            };
        },
        mailingList: () => {
            const table = generateTable({
                name: "mailing_list",
                columns: [
                    ...FIRST_NAME_LAST_NAME,
                    {
                        type: "email",
                        dependOn: [-1, -2]
                    },
                    {
                        name: ["Joined", "JoinDate"],
                        single: ["join date", "join date"],
                        plural: ["join dates", "join dates"],
                        type: "date",
                        daysBack: 365 * 3
                    },
                    oneOf([
                        {
                            name: ["Promotions", "PromotionsSent"],
                            single: [
                                "number of promotions sent",
                                "number of promotions sent"
                            ],
                            plural: [
                                "number of promotions sent",
                                "number of promotions sent"
                            ],
                            type: "number",
                            min: 0,
                            max: 10
                        },
                        {
                            name: ["PasswordChanges", "PassChangeCount"],
                            single: [
                                "number of password changes",
                                "number of password changes"
                            ],
                            plural: [
                                "number of password changes",
                                "number of password changes"
                            ],
                            type: "number",
                            min: 0,
                            max: 10
                        },
                        {
                            name: ["NumberOfKids", "Children"],
                            single: ["number of kids", "number of children"],
                            plural: ["number of kids", "number of children"],
                            type: "number",
                            min: 0,
                            max: 4
                        }
                    ])
                ],
                numberOfRows: DEFAULT_NUMBER_OF_ROWS,
                rules: {}
            });

            return {
                type: "whole-table",
                db: [table],
                values: [],
                brief: [
                    "A mailing list of an illegal online service was sent to the SQLPD hot-line."
                ]
            };
        }
    }
    // TODO money: {}, // 'payments|transactions',
    // TODO stuff: {}, // 'stock|inventory',
};

const commaSeparatedColumnNames = ({ headers }, isSingle = false) =>
    headers
        .map(({ single, plural }) => (isSingle ? single : plural))
        .join(", ")
        .replace(/(.*), (.*)/, "$1 and $2");

const tableHasFieldTypes = ({ headers }, fieldTypes) =>
    fieldTypes.every(type => headers.map(h => h.type).includes(type));

const findOneOf = (arr, optionsArr) =>
    arr.reduce(
        (itemFound, item) =>
            itemFound ? itemFound : optionsArr.includes(item) ? item : "",
        ""
    );

const months = [
    undefined,
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const dateToWords = date => {
    // 2019-09-05 to September 5th 2019
    const [year, month, day] = date.split("-");
    const dateStr = `${months[Number(month)]} ${Number(day)}${
        day.endsWith("1")
            ? "st"
            : day.endsWith("2")
            ? "nd"
            : day.endsWith("3")
            ? "rd"
            : "th"
    } ${year}`;
    return dateStr;
};

const timeToWords = time => {
    const [hour, minute] = time.split(":");
    const ampm = Number(hour) - 12 > 0 ? "PM" : "AM";
    const ampmHour = Number(hour) - 12 > 0 ? Number(hour) - 12 : Number(hour);
    const timeStr = `${ampmHour}:${minute}${ampm}`;
    return timeStr;
};

const dateOrTimestampToWords = time => {
    // 2019-09-05 03:04:33 to September 5th 2019 at 3:04AM
    // 2019-09-05 to September 5th 2019
    const [datePart, timePart] = time.split(" ");
    return !timePart
        ? dateToWords(datePart)
        : `${dateToWords(datePart)} at ${timeToWords(timePart)}`;
};

const whereConditionToWords = ({
    table,
    column,
    operator,
    value,
    type,
    boolOp
}) => {
    if (type === "number") {
        if (operator === "in" || operator === "not in") {
            // Value in this case is an array of possible values
            return `
        ${oneOf(
            operator.includes("not")
                ? ["that do not have", "without"]
                : ["that have", "with"]
        )}
        ${value
            .join(", ")
            .trim()
            .replace(/, ([^,]+)$/, " or $1")}
        ${headerContentPlural(table, column).replace("number of ", "")}
        ${boolOp || ""}
      `;
        }

        if (operator === "between" || operator === "not between") {
            // Value in this case is an array with 2 values [min, max]
            const [min, max] = value;
            const [condStart, condEnd] = operator.includes("not")
                ? oneOf([
                      ["with less than", "or more than"],
                      ["that do not have between", "and"]
                  ])
                : oneOf([
                      ["that have at least", "but not more than"],
                      ["that have between", "and"]
                  ]);

            return `
        ${condStart}
        ${min}
        ${condEnd}
        ${max}
        ${headerContentPlural(table, column).replace("number of ", "")}
        ${boolOp || ""}
      `;
        }

        // Value is an array with one element
        return `${
            {
                "=": oneOf(["that have", "with"]),
                "!=": oneOf(["that do not have"]),
                ">": oneOf(["that have more than", "with more than"]),
                "<": oneOf(["that have less than", "with less than"]),
                ">=": oneOf(["that have at least", "with at least"]),
                "<=": oneOf(["that have at most", "with at most"])
            }[operator]
        } ${
            value[0] === 0 && operator === "=" ? "no" : value[0]
        } ${(value[0] === 1 ? headerContentSingle : headerContentPlural)(
            table,
            column
        ).replace("number of ", "")} ${boolOp || ""}`;
    } else if (type === "date" || type === "timestamp") {
        if (operator === "between" || operator === "not between") {
            // Value in this case is an array with 2 values [min, max]
            const [min, max] = value;
            const [condStart, condEnd] = operator.includes("not")
                ? oneOf([["before", "or after"], ["not between", "and"]])
                : oneOf([["on or after", "but not after"], ["between", "and"]]);

            return `
        with ${headerContentPlural(table, column)}
        ${condStart}
        ${dateOrTimestampToWords(min)}
        ${condEnd}
        ${dateOrTimestampToWords(max)}
        ${boolOp || ""}
      `;
        }

        return `with ${headerContentPlural(table, column)}
      ${
          {
              "=": oneOf(["of", "on"]),
              "!=": "not on",
              ">": "after",
              "<": "before",
              ">=": "on or after",
              "<=": "that came before or on"
          }[operator]
      } ${dateOrTimestampToWords(value[0])} ${boolOp || ""}
      `;
    } else if (type === "string") {
        if (operator === "in" || operator === "not in") {
            // Value in this case is an array of possible values
            return `
        ${
            operator.includes("not")
                ? `that do not have a ${headerContentSingle(table, column)} of`
                : `that have a ${headerContentSingle(table, column)} of`
        }
        ${value
            .join(", ")
            .trim()
            .replace(/, ([^,]+)$/, " or $1")}
        ${boolOp || ""}
      `;
        }

        // Value is an array with one element
        return `
      ${
          {
              "=": `that have a ${headerContentSingle(table, column)} of`,
              "!=": `that do not have a ${headerContentSingle(
                  table,
                  column
              )} of`,
              ">": `whose ${headerContentSingle(table, column)} is bigger than`,
              "<": `whose ${headerContentSingle(
                  table,
                  column
              )} is smaller than`,
              ">=": `whose ${headerContentSingle(
                  table,
                  column
              )} is bigger than or equal to`,
              "<=": `whose ${headerContentSingle(
                  table,
                  column
              )} is smaller than or equal to`
          }[operator]
      } ${value[0]} ${boolOp || ""}
      `;
    }
};

function briefStatement(attributes, mission) {
    let statement = mission.brief[0];
    const tableName = mission.db[0].name;
    const content = tableName.includes("_")
        ? oneOf(["entries", "records"]) // Can't use tableName.replace('_', ' ') because of mailing_list and where combination
        : tableName;

    const whereClause = !mission.where
        ? "' details"
        : ` ${mission.where.map(whereConditionToWords).join(" ")} `;

    const sortClause =
        attributes.includes("sort-one") ||
        attributes.includes("sort-one-desc") ||
        attributes.includes("sorted")
            ? ` ${attributes.includes("limit") ? "when " : ""}sorted by ` +
              mission.sort
                  .map(
                      ([colname, order]) =>
                          `${headerContentPlural(mission.db[0], colname)} in ${
                              order === "ASC" ? "ascending" : "descending"
                          } order`
                  )
                  .join(" and then by ")
            : "";

    const limitClause = attributes.includes("limit")
        ? "the top " + numberOfRows(mission.resultTable)
        : "all";

    if (attributes.includes("table")) {
        statement += " Please submit " + limitClause + " " + content;
        statement += whereClause;
        statement += sortClause;
        statement = statement.trim() + ".";
    } else if (
        attributes.includes("columns") ||
        attributes.includes("one-column")
    ) {
        statement +=
            " Please submit " +
            limitClause +
            " " +
            (
                content +
                " " +
                commaSeparatedColumnNames(mission.resultTable) +
                whereClause +
                sortClause
            ).trim() +
            ".";
    } else {
        throw new Error("Unsupported attributes in mission generation");
    }

    if (attributes.includes("no-dups")) {
        statement += " Please make sure there are no duplicates.";
    }
    return statement;
}

const WHERE_TYPES = ["number", "date", "timestamp", "string"];
const WHERE_OPERATORS = [
    "=",
    "><",
    "><=",
    "!=",
    "in",
    "not in",
    "between",
    "not between"
];
const WHERE_BOOLEANS = ["and", "or"];

const handleWhereTypeAndOperator = (whereType, whereOperator, mission) => {
    let whereTree, where, briefValues;

    const possibleWhereColumns = mission.db[0].headers
        .filter(header => header.name !== "#")
        .filter(header => whereType === header.type)
        .map(header => header.name);
    // console.log(possibleWhereColumns);

    const whereChosenColumn = oneOf(possibleWhereColumns);
    const wherePossibleValues = values(mission.db[0], whereChosenColumn).filter(
        (v, i, a) => a.indexOf(v) === i
    );
    const chosenValues = [];

    const effectiveOperator = {
        "=": "=",
        "!=": "!=",
        "><": oneOf([">", "<"]),
        "><=": oneOf([">=", "<="]),
        in: "in",
        "not in": "not in",
        between: "between",
        "not between": "not between"
    }[whereOperator];

    if (effectiveOperator === "in" || effectiveOperator === "not in") {
        chosenValues.push(...choose(wherePossibleValues, 3 /* TODO */));
        whereTree = {
            op: effectiveOperator,
            left: {
                column: whereChosenColumn
            },
            right: {
                type: "list",
                value: chosenValues
            }
        };
    } else if (
        effectiveOperator === "between" ||
        effectiveOperator === "not between"
    ) {
        const [low, high] = choose(wherePossibleValues, 2).sort((a, b) => {
            if (typeof a === "number") {
                return a - b;
            } else {
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            }
        });
        chosenValues.push(low, high);
        const isNot = effectiveOperator.includes("not");
        whereTree = {
            op: isNot ? "or" : "and",
            left: {
                op: isNot ? "<" : ">=",
                left: {
                    column: whereChosenColumn
                },
                right: {
                    type: whereType === "number" ? "number" : "string",
                    value: low
                }
            },
            right: {
                op: isNot ? ">" : "<=",
                left: {
                    column: whereChosenColumn
                },
                right: {
                    type: whereType === "number" ? "number" : "string",
                    value: high
                }
            }
        };
    } else {
        // Regular operators
        const minValue = wherePossibleValues.reduce((min, cur) =>
            min < cur ? min : cur
        );
        const maxValue = wherePossibleValues.reduce((max, cur) =>
            max > cur ? max : cur
        );
        const chosenValue = oneOf(
            whereOperator !== "><"
                ? wherePossibleValues
                : wherePossibleValues.filter(
                      val => val !== minValue && val != maxValue
                  )
        );
        chosenValues.push(chosenValue);

        whereTree = {
            op: effectiveOperator,
            left: {
                column: whereChosenColumn
            },
            right: {
                type: whereType === "number" ? "number" : "string",
                value: chosenValue
            }
        };
    }

    where = {
        table: mission.db[0],
        column: whereChosenColumn,
        operator: effectiveOperator,
        value: chosenValues,
        type: whereType
    };

    briefValues = chosenValues.map(val =>
        whereType === "number" ? "" + val : `'${val}'`
    );

    return { where, whereTree, briefValues };
};

function generateMission(attributes) {
    // console.log('Generating brief for', JSON.stringify(attributes));
    let mission;
    let necessaryFieldTypes = [
        attributes.includes("number") ? "number" : "",
        attributes.includes("date") ? "date" : "",
        attributes.includes("timestamp") ? "timestamp" : "",
        attributes.includes("string") ? "string" : ""
    ].filter(x => x);

    do {
        // Generate a table
        let tableType = oneOf(Object.keys(tables));
        // console.log('Using the ' + tableType + ' table');
        let tableSubtype = oneOf(Object.keys(tables[tableType]));
        // console.log('Using the ' + tableSubtype + ' subtype');
        mission = tables[tableType][tableSubtype]();
        mission.db[0] = addRowNumbers(mission.db[0]);
        // console.log(necessaryFieldTypes);
    } while (!tableHasFieldTypes(mission.db[0], necessaryFieldTypes));

    const possibleColumns = mission.db[0].headers
        .filter(header => header.name !== "#")
        .map(header => header.name);

    const whereAttributes = attributes.filter(
        v =>
            WHERE_TYPES.includes(v) ||
            WHERE_OPERATORS.includes(v) ||
            WHERE_BOOLEANS.includes(v)
    );
    if (whereAttributes.length) {
        // Assume for now [ type, op ] or [ type, op, bool, type, op ]
        // console.log(whereAttributes);
        const whereType = whereAttributes.shift();
        const whereOperator = whereAttributes.shift();
        const { where, whereTree, briefValues } = handleWhereTypeAndOperator(
            whereType,
            whereOperator,
            mission
        );

        const whereBoolean = whereAttributes.shift();
        if (!whereBoolean) {
            mission.where = [where];
            mission.whereTree = whereTree;
            mission.values.push(...briefValues);
        } else {
            const secondWhereType = whereAttributes.shift();
            const secondWhereOperator = whereAttributes.shift();
            const {
                where: secondWhere,
                whereTree: secondWhereTree,
                briefValues: secondBriefValues
            } = handleWhereTypeAndOperator(
                secondWhereType,
                secondWhereOperator,
                mission
            );

            mission.where = [{ ...where, boolOp: whereBoolean }, secondWhere];
            mission.whereTree = {
                op: whereBoolean,
                left: whereTree,
                right: secondWhereTree
            };
            mission.values.push(...briefValues, ...secondBriefValues);
        }
    }

    if (mission.where && mission.whereTree) {
        // console.log(JSON.stringify(mission.whereTree, undefined, 4));
        // console.table(mission.where);
    }

    const numOfResultColumns = attributes.includes("one-column")
        ? 1
        : attributes.includes("columns")
        ? number(2, 3 /* possibleColumns.length - 1 was too much */)
        : possibleColumns.length;

    if (
        attributes.includes("columns") ||
        attributes.includes("one-column") ||
        attributes.includes("table")
    ) {
        // Generate expected resultTable
        if (mission.where) {
            mission.resultTable = filter(
                mission.resultTable || mission.db[0],
                mission.whereTree
            );
        }

        const chosenColumns = choose(possibleColumns, numOfResultColumns);
        mission.resultTable = project(mission.resultTable || mission.db[0], [
            { column: "#" },
            ...chosenColumns.map(name => ({ column: name }))
        ]);
    }

    if (attributes.includes("no-dups")) {
        mission.resultTable = distinct(mission.resultTable);
    }

    if (
        attributes.includes("sort-one") ||
        attributes.includes("sort-one-desc") ||
        attributes.includes("sorted")
    ) {
        // Select columns to sort by
        const table = mission.resultTable || mission.db[0];
        const possibleColumns = table.headers
            .filter(header => header.name !== "#")
            .map(header => header.name);
        const chosenColumns = choose(
            possibleColumns,
            attributes.includes("sort-one") ||
                attributes.includes("sort-one-desc") ||
                attributes.includes("one-column")
                ? 1
                : 2
        );
        mission.sort = chosenColumns.map(colname => [
            colname,
            attributes.includes("sort-one")
                ? "ASC"
                : attributes.includes("sort-one-desc")
                ? "DESC"
                : oneOf(["ASC", "DESC"])
        ]);
        // update result table
        mission.resultTable = orderBy(
            table,
            mission.sort.map(([colname, order]) => ({
                column: colname,
                desc: order === "DESC"
            }))
        );
    }

    if (attributes.includes("limit")) {
        // Limit the number of rows
        const numOfRows = oneOf([3, 5, 10, 20]);
        const table = mission.resultTable || mission.db[0];
        mission.resultTable = limit(table, numOfRows);
        // Allow user to choose the number from brief values tab
        mission.values.push("" + numOfRows);
    }

    if (attributes.includes("no-dups")) {
        // Make sure no-dups missions actually have duplicates by adding some
        // of the end result records twice
        const finalRowNumbers = extractRowNumbers(
            mission.resultTable || mission.db[0]
        );
        const chosenRecordIndices = choose(
            finalRowNumbers,
            number(1, Math.min(finalRowNumbers.length, 3))
        );
        chosenRecordIndices.forEach(i =>
            mission.db[0].data.push(mission.db[0].data[i].slice(0))
        );
    }

    mission.db[0] = removeRowNumbers(mission.db[0]);
    if (mission.resultTable) {
        mission.resultTable = removeRowNumbers(mission.resultTable);
    }
    mission.brief[0] = briefStatement(attributes, mission);

    // console.log(mission);
    return mission;
}

export { generateMission };
