import { tokenize } from './tokenizer.js';

const findIndex = (array, cb) => array.indexOf(array.filter(cb).shift());

const findKeywordCaseInsensitive = (tokens, token) =>
  findIndex(tokens, t => t.type === 'token' && t.value.toLowerCase() === token);

const STAR = { type: 'reserved', value: '*' };
const COMMA = { type: 'reserved', value: ',' };
const OPEN_BRACE = { type: 'reserved', value: '(' };
const CLOSE_BRACE = { type: 'reserved', value: ')' };
const AND_TOKEN = { type: 'token', value: 'and' };
const OR_TOKEN = { type: 'token', value: 'or' };
const IS_NULL_TOKEN = { type: 'token', value: 'is null' };
const IS_NOT_NULL_TOKEN = { type: 'token', value: 'is not null' };
const IN_TOKEN = { type: 'token', value: 'in' };
const NOT_IN_TOKEN = { type: 'token', value: 'not in' };
const LTE_TOKEN = { type: 'reserved', value: '<=' };
const GTE_TOKEN = { type: 'reserved', value: '>=' };
const NE_TOKEN = { type: 'reserved', value: '<>' };
const EXC_NE_TOKEN = { type: 'reserved', value: '!=' };
const EQ_TOKEN = { type: 'reserved', value: '=' };
const LT_TOKEN = { type: 'reserved', value: '<' };
const GT_TOKEN = { type: 'reserved', value: '>' };
const DESC_TOKEN = { type: 'token', value: 'desc' };
const ASC_TOKEN = { type: 'token', value: 'asc' };
const AS_TOKEN = { type: 'token', value: 'as' };
const INNER_JOIN_TOKEN = { type: 'token', value: 'inner join' };
const ON_TOKEN = { type: 'token', value: 'on' };
const SEMICOLON_TOKEN = { type: 'reserved', value: ';' };
const BETWEEN_TOKEN = { type: 'token', value: 'between' };
const NOT_BETWEEN_TOKEN = { type: 'token', value: 'not between' };

const separateClauses = tokens => {
  const selectStart = findKeywordCaseInsensitive(tokens, 'select');
  const fromStart = findKeywordCaseInsensitive(tokens, 'from');
  const whereStart = findKeywordCaseInsensitive(tokens, 'where');
  const groupByStart = findKeywordCaseInsensitive(tokens, 'group by');
  const havingStart = findKeywordCaseInsensitive(tokens, 'having');
  const orderByStart = findKeywordCaseInsensitive(tokens, 'order by');
  const limitStart = findKeywordCaseInsensitive(tokens, 'limit');
  const nextClause = [
    fromStart,
    whereStart,
    groupByStart,
    havingStart,
    orderByStart,
    limitStart,
    tokens.length + 1,
  ].filter(i => i !== -1);
  const clauses = {};

  const addClause = (start, key) => {
    if (start !== -1) {
      clauses[key] = tokens.slice(start + 1, nextClause.shift());
    }
  };

  const addStringClause = (start, key) => {
    if (start !== -1) {
      clauses[key] = tokensToString(
        tokens.slice(start + 1, nextClause.shift()),
      );
    }
  };

  addClause(selectStart, 'select');
  addClause(fromStart, 'from');
  addClause(whereStart, 'where');
  addClause(groupByStart, 'groupBy');
  addClause(havingStart, 'having');
  addClause(orderByStart, 'orderBy');
  addClause(limitStart, 'limit');
  return clauses;
};

const tokenIs = (
  { type, value },
  { type: expectedType, value: expectedValue },
) => type === expectedType && value === expectedValue;

const tokenIsNot = (...args) => !tokenIs(...args);

const throwIf = (condition, errorMessage) => {
  if (condition) {
    throw new Error(errorMessage);
  }
};

const assertNextToken = (stream, expectedToken, errorMessage) => {
  const token = stream.shift();
  throwIf(
    tokenIsNot(token, expectedToken),
    errorMessage || `Expected ${expectedToken.value} instead of ${token.value}`,
  );
};

const extractIfNextToken = (stream, expectedToken) => {
  const token = stream[0];
  if (token && tokenIs(token, expectedToken)) {
    stream.shift();
    return true;
  }
  return false;
};

const extractNumber = (tokensStream, errorMessage) => {
  throwIf(!tokensStream.length, errorMessage);
  const { type, value } = tokensStream.shift();
  throwIf(type !== 'number', errorMessage);
  return Number(value);
};

const extractExpression = (tokensStream, options = {}) => {
  const { allowStar, allowAgg, onlyValues, allowAliases } = options;
  const token = tokensStream.shift();
  if (tokenIs(token, STAR)) {
    throwIf(!allowStar, 'Illegal use of *');
    return { column: '*' };
  }
  throwIf(tokenIs(token, COMMA), 'Incorrectly placed ,');
  if (token.type === 'string' || token.type === 'number') {
    const expression = { ...token };
    if (allowAliases && extractIfNextToken(tokensStream, AS_TOKEN)) {
      expression.alias = tokensStream.shift().value;
    }

    return expression;
  }
  throwIf(onlyValues, 'Only strings are supported as list values currently');
  if (
    token.type === 'token' &&
    ['min', 'max', 'sum', 'avg', 'count'].includes(token.value.toLowerCase())
  ) {
    const func = token.value.toLowerCase();
    throwIf(!allowAgg, `Can not use ${func} at this point`);
    assertNextToken(tokensStream, OPEN_BRACE);

    const column = tokensStream.shift();
    if (token.value.toLowerCase() === 'count') {
      throwIf(
        tokenIsNot(column, STAR),
        'Only * is supported as an argument for count',
      );
    } else {
      throwIf(
        column.type !== 'token',
        `Only columns are supported as an argument for ${token.value}`,
      );
    }

    assertNextToken(tokensStream, CLOSE_BRACE);

    let alias;
    if (allowAliases && extractIfNextToken(tokensStream, AS_TOKEN)) {
      alias = tokensStream.shift().value;
    }

    return {
      func,
      alias,
      column: column.value,
    };
  }

  // When all else fails, it's a column name
  const columnExpression = { column: token.value };
  if (allowAliases && extractIfNextToken(tokensStream, AS_TOKEN)) {
    columnExpression.alias = tokensStream.shift().value;
  }
  return columnExpression;
};

const parseSelectClause = tokens => {
  const tokensStream = tokens.slice(0);
  const distinct =
    tokensStream.length &&
    tokensStream[0].type !== 'number' &&
    tokensStream[0].value.toLowerCase() === 'distinct';
  const select = [];

  if (distinct) {
    tokensStream.shift();
  }

  if (!tokensStream.length) {
    throw new Error(
      'Please specify a list of fields or &nbsp;*&nbsp; in the SELECT clause',
    );
  }

  // For now, assume it's one of string, number, column or func(column)
  // separated by commas

  while (tokensStream.length) {
    select.push(
      extractExpression(tokensStream, {
        allowStar: true,
        allowAgg: true,
        allowAliases: true,
      }),
    );
    if (tokensStream.length) {
      assertNextToken(tokensStream, COMMA);
    }
  }

  return { select, distinct };
};

const parseFromClause = tokens => {
  const tokensStream = tokens.slice(0);
  if (!tokensStream.length) {
    return null;
  }

  const left = { table: tokensStream.shift().value };
  if (extractIfNextToken(tokensStream, INNER_JOIN_TOKEN)) {
    const right = { table: tokensStream.shift().value };
    assertNextToken(tokensStream, ON_TOKEN);
    const on = parseCondition(tokensStream);
    const join = { join: 'inner', left, right, on };
    return join;
  }

  return left;
};

const extractValueList = tokens => {
  // Mutates the tokens stream to advance the cursor till after the list
  assertNextToken(tokens, OPEN_BRACE);
  const value = [];
  while (tokens.length && tokenIsNot(tokens[0], CLOSE_BRACE)) {
    value.push(
      extractExpression(tokens, {
        onlyValues: true,
      }).value,
    );
    throwIf(
      tokens.length &&
        tokenIsNot(tokens[0], CLOSE_BRACE) &&
        tokenIsNot(tokens[0], COMMA),
      'Expected a comma or a close bracket in the list',
    );
    if (tokenIs(tokens[0], COMMA)) {
      tokens.shift();
    }
  }
  assertNextToken(tokens, CLOSE_BRACE);

  return {
    type: 'list',
    value,
  };
};

const extractLiteral = (tokens, errorMessage) => {
  // tokens stream is MODIFIED by this function to advance to the next position when a
  // literal was found.
  const token = tokens.shift();
  if (token.type === 'string' || token.type === 'number') {
    return token;
  }
  throw new Error(errorMessage || 'Expected a literal');
};

const extractColumnOrLiteral = (tokens, allowAgg) => {
  // tokens stream is MODIFIED by this function to advance to the next position when a
  // literal was found.
  const token = tokens.shift();

  // String or number literal
  if (token.type === 'string' || token.type === 'number') {
    return token;
  }

  if (token.type === 'token') {
    if (
      ['min', 'max', 'sum', 'avg', 'count'].includes(token.value.toLowerCase())
    ) {
      // aggFunc(starOrColumn)
      const func = token.value.toLowerCase();
      throwIf(!allowAgg, `Can not use ${func} at this point`);
      assertNextToken(tokens, OPEN_BRACE);

      const column = tokens.shift();
      if (token.value.toLowerCase() === 'count') {
        throwIf(
          tokenIsNot(column, STAR),
          'Only * is supported as an argument for count',
        );
      } else {
        throwIf(
          column.type !== 'token',
          `Only columns are supported as an argument for ${token.value}`,
        );
      }

      assertNextToken(tokens, CLOSE_BRACE);
      return { func, column: column.value };
    } else {
      // [tableName.]columnName
      return { column: token.value };
    }
  }

  throw new Error('Expected a column, string or number');
};

// Basic condition is column binary operator value
// or column is null, or column is not null
// or column in list, or column not in list
// or column between literal and literal
// or column not between literal and literal
// if allowAgg then column can also be an aggregation function
const extractBasicCondition = (tokens, allowAgg = false) => {
  // tokens stream is MODIFIED by this function to keep the relevant position
  // after returning in the recursion
  const left = extractColumnOrLiteral(tokens, allowAgg);
  const token = tokens.shift();
  if (
    tokenIs(token, LTE_TOKEN) ||
    tokenIs(token, GTE_TOKEN) ||
    tokenIs(token, NE_TOKEN) ||
    tokenIs(token, EXC_NE_TOKEN) ||
    tokenIs(token, EQ_TOKEN) ||
    tokenIs(token, LT_TOKEN) ||
    tokenIs(token, GT_TOKEN)
  ) {
    return {
      op: token.value,
      left,
      right: extractColumnOrLiteral(tokens),
    };
  } else if (
    tokenIs(token, IS_NULL_TOKEN) ||
    tokenIs(token, IS_NOT_NULL_TOKEN)
  ) {
    return {
      op: token.value,
      left,
    };
  } else if (tokenIs(token, IN_TOKEN) || tokenIs(token, NOT_IN_TOKEN)) {
    return {
      op: token.value,
      left,
      right: extractValueList(tokens),
    };
  } else if (
    tokenIs(token, BETWEEN_TOKEN) ||
    tokenIs(token, NOT_BETWEEN_TOKEN)
  ) {
    const isNot = token.value.includes('not');
    const low = extractLiteral(tokens);
    assertNextToken(tokens, AND_TOKEN);
    const high = extractLiteral(tokens);
    return {
      op: isNot ? 'or' : 'and',
      left: {
        op: isNot ? '<' : '>=',
        left,
        right: low,
      },
      right: {
        op: isNot ? '>' : '<=',
        left,
        right: high,
      },
    };
  } else {
    throw new Error('Unrecognised operator ' + token.value);
  }
};

const PREFIX_PARSLETS = {};
const INFIX_PARSLETS = {};
const INFIX_PRECEDENCE = {};

// function registerName() {
//   PREFIX_PARSLETS.name = (tokens, token) => token.value;
// }

// function registerPrefix(operator, precedence) {
//   PREFIX_PARSLETS[operator] = (tokens, token) => ({
//     operator: token.value,
//     operand: parseConditionExpression(tokens, precedence),
//   });
// }

function registerPrefixBrace(operator) {
  PREFIX_PARSLETS[operator] = (tokens, allowAgg, token) => {
    const operand = parseConditionExpression(tokens, allowAgg, 0);
    const nextToken = tokens.shift();
    if (!nextToken || nextToken.value !== ')') {
      throw new Error('Missing closing brace.');
    }
    return {
      op: token.value,
      left: operand,
    };
  };
}

function registerInfix(operator, precedence) {
  INFIX_PRECEDENCE[operator] = precedence;
  INFIX_PARSLETS[operator] = (tokens, allowAgg, left, token) => ({
    op: token.value,
    left,
    right: parseConditionExpression(tokens, allowAgg, precedence),
  });
}

// function registerPostfix(operator, precedence) {
//  INFIX_PRECEDENCE[operator] = precedence;
//  INFIX_PARSLETS[operator] = (tokens, allowAgg, left, token) => ({
//    op: token.value,
//    left,
//  });
// }

function peekNextTokenPrecedence(tokens) {
  const lookAheadToken = tokens[0];
  if (!lookAheadToken) {
    return 0;
  }
  return INFIX_PRECEDENCE[lookAheadToken.value] || 0;
}

function parseConditionExpression(tokens, allowAgg, precedence = 0) {
  let token = tokens.shift();
  if (!token) {
    throw new Error('Missing condition');
  }
  const prefix = PREFIX_PARSLETS[token.value];
  let left;
  if (prefix) {
    left = prefix(tokens, allowAgg, token);
  } else {
    tokens.unshift(token);
    left = extractBasicCondition(tokens, allowAgg);
  }

  while (precedence < peekNextTokenPrecedence(tokens)) {
    token = tokens.shift();
    const infix = INFIX_PARSLETS[token.value];
    left = infix(tokens, allowAgg, left, token);
  }
  return left;
}

registerPrefixBrace('(');
registerInfix('or', 10);
registerInfix('and', 20);

const parseCondition = (tokens, allowAgg = false) => {
  const condition = parseConditionExpression(tokens, allowAgg, 0);
  if (tokens.length) {
    throw new Error('Extra tokens at the end of the condition');
  }
  return condition;
};

const parseGroupByClause = tokens => {
  const tokensStream = tokens.slice(0);
  const groupBy = [];

  // For now, assume it's only columns separated by commas

  while (tokensStream.length) {
    groupBy.push(extractExpression(tokensStream));
    if (tokensStream.length) {
      assertNextToken(tokensStream, COMMA);
    }
  }

  return groupBy;
};

const parseOrderByClause = tokens => {
  const tokensStream = tokens.slice(0);
  const order = [];
  while (tokensStream.length) {
    order.push(extractExpression(tokensStream));
    order[order.length - 1].desc = false;
    if (
      tokensStream.length &&
      (tokenIs(tokensStream[0], DESC_TOKEN) ||
        tokenIs(tokensStream[0], ASC_TOKEN))
    ) {
      const isDesc = tokensStream.shift().value === 'desc';
      order[order.length - 1].desc = isDesc;
    }
    if (tokensStream.length) {
      throwIf(tokenIsNot(tokensStream[0], COMMA));
      tokensStream.shift();
    }
  }
  return order;
};

const parseLimitClause = tokens =>
  extractNumber(tokens, 'Expected a number after LIMIT');

const validateOnlyOneStatement = str => {
  const tokens = tokenize(str);
  const semicolonPosition = findIndex(
    tokens,
    t => t.type === SEMICOLON_TOKEN.type && t.value === SEMICOLON_TOKEN.value,
  );
  if (semicolonPosition !== -1 && tokens.length > semicolonPosition + 1) {
    throw new Error('Multiple statements are not supported.');
  }
  return semicolonPosition === -1 ? tokens : tokens.slice(0, semicolonPosition);
};

export function parse(str) {
  const tokens = validateOnlyOneStatement(str);
  const clauses = separateClauses(tokens);
  const { select, distinct } = clauses.select
    ? parseSelectClause(clauses.select)
    : {};
  const statement = {
    select,
    distinct: distinct ? true : undefined,
    from: clauses.from ? parseFromClause(clauses.from) : undefined,
    where: clauses.where
      ? parseCondition(clauses.where, false /* No aggregation */)
      : undefined,
    groupBy: clauses.groupBy ? parseGroupByClause(clauses.groupBy) : undefined,
    having: clauses.having
      ? parseCondition(clauses.having, true /* allow aggregation */)
      : undefined,
    orderBy: clauses.orderBy ? parseOrderByClause(clauses.orderBy) : undefined,
    limit: clauses.limit ? parseLimitClause(clauses.limit) : undefined,
  };
  return statement;
}

// console.log(parse('SELECT count(*) from table'));
