import {
  seed,
  generateTable,
  declareVariables,
  variable,
  encouragement,
} from './generator/generator';

import {
  toString /* TODO REMOVE */,
  equivalent /* TODO Move to server */,
  orderBy /* TODO Move to server */,
  distinct /* TODO Move to server */,
  headerContentPlural,
  numberOfRows,
} from './sql/table';

import { generateMission } from './brief';

// Some simple utils, will be moved later
const prefixAOrAn = (str, capitalise = false) =>
  (capitalise ? 'A' : 'a') +
  ('aeiouAEIOU'.indexOf(str.trim()[0]) === -1 ? '' : 'n') +
  ' ' +
  str;

const capitaliseFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);
// End utils

const months = [
  undefined,
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const timestampToWords = timestamp => {
  // 2019-09-05 03:04:33 to September 5th 2019 at 3:04AM
  const [datePart, timePart] = timestamp.split(' ');
  const [year, month, day] = datePart.split('-');
  const dateStr = `${months[Number(month)]} ${Number(day)}${
    day.endsWith('1')
      ? 'st'
      : day.endsWith('2')
      ? 'nd'
      : day.endsWith('3')
      ? 'rd'
      : 'th'
  } ${year}`;
  const [hour, minute] = timePart.split(':');
  const ampm = Number(hour) - 12 > 0 ? 'PM' : 'AM';
  const ampmHour = Number(hour) - 12 > 0 ? Number(hour) - 12 : Number(hour);
  const timeStr = `${ampmHour}:${minute}${ampm}`;

  return `${dateStr} at ${timeStr}`;
};

export default (
  { rank, solved, missionAttributes: attributes },
  seedString,
) => {
  // TODO? reinstate this: seed(seedString);
  const mission = generateMission(attributes);
  const instructions = {
    'fill-in': `
      Use SQL and choose values from the results to fill in the 
      blanks above.`,
    'whole-table': `
      Use SQL to retrieve the data and submit the resulting table.`,
  }[mission.type];

  const checkAnswer = resultsTable => {
    if (
      attributes.includes('table') ||
      attributes.includes('columns') ||
      attributes.includes('one-column')
    ) {
      // TODO Should be done on the server...
      const equiv = equivalent(
        resultsTable,
        mission.resultTable || mission.db[0],
      );
      if (!equiv.result) {
        // Is it failing since there are duplicates?
        if (
          attributes.includes('no-dups') &&
          numberOfRows(resultsTable) !== numberOfRows(distinct(resultsTable))
        ) {
          return {
            isSuccessful: false,
            response:
              (attributes.includes('one-column')
                ? 'Some ' +
                  resultsTable.headers[0].plural +
                  ' appear more than once.'
                : 'Results contain duplicate entries.') +
              '<BR>Did you use DISTINCT?',
          };
        }

        if (attributes.includes('limit')) {
          // Is it failing since limit was forgotten?
          const expectedNumberOfRows = numberOfRows(mission.resultTable);
          if (numberOfRows(resultsTable) !== expectedNumberOfRows) {
            return {
              isSuccessful: false,
              response:
                'Expected to get only the top ' +
                expectedNumberOfRows +
                ' rows.<BR>Did you use LIMIT?',
            };
          }
        }

        if (mission.where && equiv.reason === 'Unexpected rows') {
          return {
            isSuccessful: false,
            response:
              'Result does not match the request.<BR>Did you use the right conditions in the WHERE clause?',
          };
        }
      }

      if (
        attributes.includes('sort-one') ||
        attributes.includes('sort-one-desc') ||
        attributes.includes('sorted')
      ) {
        // Is it equivalent but the order is wrong?
        if (equiv.result && !equiv.sameOrder) {
          return {
            isSuccessful: false,
            response:
              'Content seems ok, but the ordering is off.<BR>Did you sort the resulting table?',
          };
        }
      }

      let columnContentPlural = '';
      const relevantColumnMatch =
        /Missing column (.*)/.exec(equiv.reason) ||
        /Unexpected column (.*)/.exec(equiv.reason);
      if (relevantColumnMatch && relevantColumnMatch[1]) {
        if (/Missing column/.test(equiv.reason)) {
          columnContentPlural = headerContentPlural(
            mission.resultTable,
            relevantColumnMatch[1],
          );
        }
        if (/Unexpected column/.test(equiv.reason)) {
          columnContentPlural = headerContentPlural(
            resultsTable,
            relevantColumnMatch[1],
          );
        }
      }

      const reason = !equiv.reason
        ? ''
        : equiv.reason
            .replace(
              /Missing column (.*)/,
              capitaliseFirstLetter(
                columnContentPlural + ' should be submitted as well.',
              ),
            )
            .replace(
              /Unexpected column (.*)/,
              capitaliseFirstLetter(
                columnContentPlural + ' should not be submitted.',
              ),
            );
      return {
        isSuccessful: equiv.result,
        response: reason || encouragement(),
      };
    } else {
      throw new Error('unimplemented attribute checkAnswer');
    }
  };

  return { ...mission, instructions, checkAnswer };
};
