import Prando from "prando";
import md5 from "blueimp-md5";

export const Gender = {
    MALE: "M",
    FEMALE: "F"
};

/*
  First names taken from https://www.ssa.gov/oact/babynames/decades/century.html
  extracted using:
    [...document.querySelectorAll('tbody tr td:nth-of-type(2)')].map(e => e.innerText)
    for male and index 4 for female, all US names
*/

const maleFirstNames = [
    "James",
    "John",
    "Robert",
    "Michael",
    "William",
    "David",
    "Richard",
    "Joseph",
    "Thomas",
    "Charles",
    "Christopher",
    "Daniel",
    "Matthew",
    "Anthony",
    "Donald",
    "Mark",
    "Paul",
    "Steven",
    "Andrew",
    "Kenneth",
    "Joshua",
    "George",
    "Kevin",
    "Brian",
    "Edward",
    "Ronald",
    "Timothy",
    "Jason",
    "Jeffrey",
    "Ryan",
    "Jacob",
    "Gary",
    "Nicholas",
    "Eric",
    "Stephen",
    "Jonathan",
    "Larry",
    "Justin",
    "Scott",
    "Brandon",
    "Frank",
    "Benjamin",
    "Gregory",
    "Samuel",
    "Raymond",
    "Patrick",
    "Alexander",
    "Jack",
    "Dennis",
    "Jerry",
    "Tyler",
    "Aaron",
    "Jose",
    "Henry",
    "Douglas",
    "Adam",
    "Peter",
    "Nathan",
    "Zachary",
    "Walter",
    "Kyle",
    "Harold",
    "Carl",
    "Jeremy",
    "Keith",
    "Roger",
    "Gerald",
    "Ethan",
    "Arthur",
    "Terry",
    "Christian",
    "Sean",
    "Lawrence",
    "Austin",
    "Joe",
    "Noah",
    "Jesse",
    "Albert",
    "Bryan",
    "Billy",
    "Bruce",
    "Willie",
    "Jordan",
    "Dylan",
    "Alan",
    "Ralph",
    "Gabriel",
    "Roy",
    "Juan",
    "Wayne",
    "Eugene",
    "Logan",
    "Randy",
    "Louis",
    "Russell",
    "Vincent",
    "Philip",
    "Bobby",
    "Johnny",
    "Bradley"
];

const femaleFirstNames = [
    "Mary",
    "Patricia",
    "Jennifer",
    "Linda",
    "Elizabeth",
    "Barbara",
    "Susan",
    "Jessica",
    "Sarah",
    "Karen",
    "Nancy",
    "Margaret",
    "Lisa",
    "Betty",
    "Dorothy",
    "Sandra",
    "Ashley",
    "Kimberly",
    "Donna",
    "Emily",
    "Michelle",
    "Carol",
    "Amanda",
    "Melissa",
    "Deborah",
    "Stephanie",
    "Rebecca",
    "Laura",
    "Sharon",
    "Cynthia",
    "Kathleen",
    "Helen",
    "Amy",
    "Shirley",
    "Angela",
    "Anna",
    "Brenda",
    "Pamela",
    "Nicole",
    "Ruth",
    "Katherine",
    "Samantha",
    "Christine",
    "Emma",
    "Catherine",
    "Debra",
    "Virginia",
    "Rachel",
    "Carolyn",
    "Janet",
    "Maria",
    "Heather",
    "Diane",
    "Julie",
    "Joyce",
    "Victoria",
    "Kelly",
    "Christina",
    "Joan",
    "Evelyn",
    "Lauren",
    "Judith",
    "Olivia",
    "Frances",
    "Martha",
    "Cheryl",
    "Megan",
    "Andrea",
    "Hannah",
    "Jacqueline",
    "Ann",
    "Jean",
    "Alice",
    "Kathryn",
    "Gloria",
    "Teresa",
    "Doris",
    "Sara",
    "Janice",
    "Julia",
    "Marie",
    "Madison",
    "Grace",
    "Judy",
    "Theresa",
    "Beverly",
    "Denise",
    "Marilyn",
    "Amber",
    "Danielle",
    "Abigail",
    "Brittany",
    "Rose",
    "Diana",
    "Natalie",
    "Sophia",
    "Alexis",
    "Lori",
    "Kayla",
    "Jane"
];

const firstNames = [...maleFirstNames, ...femaleFirstNames];

/*
  100 Most Popular American Last Names
  Last names extracted from https://www.rong-chang.com/namesdict/100_last_names.htm
  using [...document.querySelectorAll('tbody tr td b a')].map(e => e.innerText)
*/
const lastNames = [
    "Smith",
    "Johnson",
    "Williams",
    "Jones",
    "Brown",
    "Davis",
    "Miller",
    "Wilson",
    "Moore",
    "Taylor",
    "Anderson",
    "Thomas",
    "Jackson",
    "White",
    "Harris",
    "Martin",
    "Thompson",
    "Garcia",
    "Martinez",
    "Robinson",
    "Clark",
    "Rodriguez",
    "Lewis",
    "Lee",
    "Walker",
    "Hall",
    "Allen",
    "Young",
    "Hernandez",
    "King",
    "Wright",
    "Lopez",
    "Hill",
    "Scott",
    "Green",
    "Adams",
    "Baker",
    "Gonzalez",
    "Nelson",
    "Carter",
    "Mitchell",
    "Perez",
    "Roberts",
    "Turner",
    "Phillips",
    "Campbell",
    "Parker",
    "Evans",
    "Edwards",
    "Collins",
    "Stewart",
    "Sanchez",
    "Morris",
    "Rogers",
    "Reed",
    "Cook",
    "Morgan",
    "Bell",
    "Murphy",
    "Bailey",
    "Rivera",
    "Cooper",
    "Richardson",
    "Cox",
    "Howard",
    "Ward",
    "Torres",
    "Peterson",
    "Gray",
    "Ramirez",
    "James",
    "Watson",
    "Brooks",
    "Kelly",
    "Sanders",
    "Price",
    "Bennett",
    "Wood",
    "Barnes",
    "Ross",
    "Henderson",
    "Coleman",
    "Jenkins",
    "Perry",
    "Powell",
    "Long",
    "Patterson",
    "Hughes",
    "Flores",
    "Washington",
    "Butler",
    "Simmons",
    "Foster",
    "Gonzales",
    "Bryant",
    "Alexander",
    "Russell",
    "Griffin",
    "Diaz",
    "Hayes"
];

// Taken from the drop down of the search in autotrader.co.uk
// Removed 'London Taxis International' though...
const carMakes = [
    "Abarth",
    "AC",
    "Aixam",
    "Alfa Romeo",
    "Alpine",
    "Ariel",
    "Aston Martin",
    "Audi",
    "Austin",
    "Bac",
    "Beauford",
    "Bentley",
    "BMW",
    "Bond",
    "Bristol",
    "Bugatti",
    "Buick",
    "Cadillac",
    "Chevrolet",
    "Chrysler",
    "Citroen",
    "Corvette",
    "Cupra",
    "Dacia",
    "Daewoo",
    "Daf",
    "Daihatsu",
    "Daimler",
    "Datsun",
    "DAX",
    "De Tomaso",
    "Dodge",
    "DS Automobiles",
    "Ferrari",
    "Fiat",
    "Ford",
    "Ginetta",
    "GMC",
    "Great Wall",
    "Holden",
    "Honda",
    "Hudson",
    "Humber",
    "Hummer",
    "Hyundai",
    "Infiniti",
    "Isuzu",
    "Jaguar",
    "Jeep",
    "Jensen",
    "Kia",
    "Koenigsegg",
    "KTM",
    "Lada",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "Levc",
    "Lexus",
    "Leyland",
    "Lincoln",
    "Lister",
    "Lotus",
    "Maserati",
    "Maybach",
    "Mazda",
    "McLaren",
    "Mercedes-Benz",
    "MG",
    "MINI",
    "Mitsubishi",
    "Mnr",
    "Morgan",
    "Morris",
    "Nissan",
    "Noble",
    "Oldsmobile",
    "Opel",
    "Perodua",
    "Peugeot",
    "Piaggio",
    "Pilgrim",
    "Plymouth",
    "Pontiac",
    "Porsche",
    "Proton",
    "Radical",
    "Reliant",
    "Renault",
    "Replica",
    "Reva",
    "Rickman",
    "Riley",
    "Rolls-Royce",
    "Rover",
    "Saab",
    "SEAT",
    "Sebring",
    "SKODA",
    "Smart",
    "Spyker",
    "Ssangyong",
    "Standard",
    "Studebaker",
    "Subaru",
    "Suzuki",
    "Tesla",
    "Toyota",
    "Triumph",
    "TVR",
    "Vauxhall",
    "Volkswagen",
    "Volvo",
    "Weineck",
    "Westfield",
    "Wolseley",
    "Yamaha",
    "Zenos"
];

const mailServers = [
    "gmail.com",
    "gmail.com",
    "gmail.com",
    "gmail.com",
    "gmail.com",
    "hotmail.com",
    "hotmail.com",
    "hotmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "me.com",
    "googlemail.com",
    "icloud.com",
    "icloud.com",
    "protonmail.com",
    "live.com"
];

/*
  Animal names from Wikipedia
  https://en.wikipedia.org/wiki/List_of_animal_names
  [...document.querySelector('table:nth-of-type(3)').querySelectorAll('td:first-of-type')].map(td => td.innerText)
*/
const animals = [
    "Aardvark",
    "Albatross",
    "Alligator",
    "Alpaca",
    "Ant",
    "Anteater",
    "Antelope",
    "Ape",
    "Armadillo",
    "Baboon",
    "Badger",
    "Barracuda",
    "Bat",
    "Bear",
    "Beaver",
    "Bee",
    "Binturong",
    "Bird",
    "Bison",
    "Bluebird",
    "Boar",
    "Bobcat",
    "Buffalo",
    "Butterfly",
    "Camel",
    "Capybara",
    "Caracal",
    "Caribou",
    "Cassowary",
    "Cat",
    "Caterpillar",
    "Cattle",
    "Chameleon",
    "Chamois",
    "Cheetah",
    "Chicken",
    "Chimpanzee",
    "Chinchilla",
    "Chough",
    "Coati",
    "Cobra",
    "Cockroach",
    "Cod",
    "Cormorant",
    "Cougar",
    "Coyote",
    "Crab",
    "Crane",
    "Cricket",
    "Crocodile",
    "Crow",
    "Cuckoo",
    "Curlew",
    "Deer",
    "Degu",
    "Dhole",
    "Dingo",
    "Dinosaur",
    "Dog",
    "Dogfish",
    "Dolphin",
    "Donkey",
    "Dotterel",
    "Dove",
    "Dragonfly",
    "Duck",
    "Dugong",
    "Dunlin",
    "Eagle",
    "Echidna",
    "Eel",
    "Eland",
    "Elephant",
    "Elephant seal",
    "Elk",
    "Emu",
    "Falcon",
    "Ferret",
    "Finch",
    "Fish",
    "Flamingo",
    "Fly",
    "Fox",
    "Frog",
    "Gaur",
    "Gazelle",
    "Gecko",
    "Gerbil",
    "Giant panda",
    "Giraffe",
    "Gnat",
    "Gnu",
    "Goat",
    "Goldfinch",
    "Goosander",
    "Goose",
    "Gorilla",
    "Goshawk",
    "Grasshopper",
    "Grouse",
    "Guanaco",
    "Guinea fowl",
    "Guinea pig",
    "Gull",
    "Hamster",
    "Hare",
    "Hawk",
    "Hedgehog",
    "Hermit crab",
    "Heron",
    "Herring",
    "Hippopotamus",
    "Hoatzin",
    "Hoopoe",
    "Hornet",
    "Horse",
    "Human",
    "Hummingbird",
    "Hyena",
    "Ibex",
    "Ibis",
    "Iguana",
    "Impala",
    "Jackal",
    "Jaguar",
    "Jay",
    "Jellyfish",
    "Jerboa",
    "Kangaroo",
    "Kingfisher",
    "Kinkajou",
    "Koala",
    "Komodo dragon",
    "Kookaburra",
    "Kouprey",
    "Kudu",
    "Lapwing",
    "Lark",
    "Lemur",
    "Leopard",
    "Lion",
    "Lizard",
    "Llama",
    "Lobster",
    "Locust",
    "Loris",
    "Louse",
    "Lynx",
    "Lyrebird",
    "Macaque",
    "Macaw",
    "Magpie",
    "Mallard",
    "Mammoth",
    "Manatee",
    "Mandrill",
    "Marmoset",
    "Marmot",
    "Meerkat",
    "Mink",
    "Mole",
    "Mongoose",
    "Monkey",
    "Moose",
    "Mosquito",
    "Mouse",
    "Myna",
    "Narwhal",
    "Newt",
    "Nightingale",
    "Nine-banded armadillo",
    "Octopus",
    "Okapi",
    "Opossum",
    "Oryx",
    "Ostrich",
    "Otter",
    "Owl",
    "Oyster",
    "Panther",
    "Parrot",
    "Panda",
    "Partridge",
    "Peafowl",
    "Pelican",
    "Penguin",
    "Pheasant",
    "Pig",
    "Pigeon",
    "Pika",
    "Polar bear",
    "Pony- See Horse",
    "Porcupine",
    "Porpoise",
    "Prairie dog",
    "Pug",
    "Quail",
    "Quelea",
    "Quetzal",
    "Rabbit",
    "Raccoon",
    "Ram",
    "Rat",
    "Raven",
    "Red deer",
    "Red panda",
    "Reindeer",
    "Rhea",
    "Rhinoceros",
    "Rook",
    "Salamander",
    "Salmon",
    "Sand dollar",
    "Sandpiper",
    "Sardine",
    "Sea lion",
    "Seahorse",
    "Seal",
    "Shark",
    "Sheep",
    "Shrew",
    "Siamang",
    "Skunk",
    "Sloth",
    "Snail",
    "Snake",
    "Spider",
    "Squid",
    "Squirrel",
    "Starling",
    "Stegosaurus",
    "Swan",
    "Tamarin",
    "Tapir",
    "Tarsier",
    "Termite",
    "Tiger",
    "Toad",
    "Toucan",
    "Turaco",
    "Turkey",
    "Turtle",
    "Umbrellabird",
    "Vicuña",
    "Vinegaroon",
    "Viper",
    "Vulture",
    "Wallaby",
    "Walrus",
    "Wasp",
    "Water buffalo",
    "Waxwing",
    "Weasel",
    "Whale",
    "Wobbegong",
    "Wolf",
    "Wolverine",
    "Wombat",
    "Woodpecker",
    "Worm",
    "Wren",
    "X-ray tetra",
    "Yak",
    "Zebra"
];

const streetNames = [...lastNames, ...animals];

const streetNameSuffixes = [
    "Avenue",
    "Place",
    "Street",
    "Close",
    "Grove",
    "Road",
    "Mews",
    "Crescent",
    "Way",
    "Terrace",
    "Park",
    "Drive",
    "Lane",
    "Alley"
];

const encouragements = [
    "Good job!",
    "Well done!",
    "Awesome!",
    "Keep up the good work!",
    "Splendid!",
    "Amazing!",
    "Way to go!",
    "Great!",
    "Very well done!"
];

let prng = new Prando();
const excludedValues = [];
let uniqueAttemptNumber = 0;
let variables = {};
let lastChosenIndex = -1;

const seed = seed => {
    prng = new Prando(seed);
};

const exclude = (...values) => {
    excludedValues.push(...values);
};

const excludeReset = () => {
    excludedValues.length = 0;
};

const CRC = ({ missionSeed, solved }) => {
    const tmpPrng = new Prando(missionSeed);
    tmpPrng.skip(solved + 7);
    return md5(tmpPrng.next());
};

/* https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array */
function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(prng.next() * (i + 1));
        // The following line messed up testcafe, reverting to simple swap
        // [a[i], a[j]] = [a[j], a[i]];
        const x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

const number = (min, max) => Math.floor(prng.next() * (max + 1 - min)) + min;
const digit = number.bind(null, 0, 9);
const oneOf = arr => {
    if (arr === undefined) {
        return undefined;
    }
    if (typeof arr === "string") {
        lastChosenIndex = 0;
        return arr;
    }
    lastChosenIndex = number(0, arr.length - 1);
    return arr[lastChosenIndex];
};
const sameOf = arr => {
    if (arr === undefined) {
        return undefined;
    }
    if (typeof arr === "string") {
        return arr;
    }
    return arr[lastChosenIndex];
};
const letters = "abcdefghijklmnopqrstuvwxyz".split("");
const letter = oneOf.bind(null, letters);
const hexDigits = "0123456789abcdef".split("");
const hexDigit = oneOf.bind(null, hexDigits);
const choose = (arr, num) => shuffle(arr.slice(0)).slice(0, num);
const firstName = gender =>
    oneOf(
        gender === Gender.MALE
            ? maleFirstNames
            : gender === Gender.FEMALE
            ? femaleFirstNames
            : firstNames
    );

const lastName = oneOf.bind(null, lastNames);
const fullName = gender => `${firstName(gender)} ${lastName()}`;
const animal = oneOf.bind(null, animals);
const street = () => `${oneOf(streetNames)} ${oneOf(streetNameSuffixes)}`;
const fullAddress = () => `${number(1, 99)} ${street()}`;
const encouragement = oneOf.bind(null, encouragements);
const policyToEmailPattern = policy => {
    if (!policy) {
        return null;
    }
    const { pattern, server } = policy;
    if (!pattern || !server) {
        return null;
    }
    return `${pattern}${
        uniqueAttemptNumber > 1 ? uniqueAttemptNumber : ""
    }@${server}`;
};
const email = (firstName, lastName, policy) => {
    const first = (!lastName
        ? firstName.split(" ").shift()
        : firstName
    ).toLowerCase();
    const last = (!lastName
        ? firstName.split(" ").pop()
        : lastName
    ).toLowerCase();
    const pattern =
        policyToEmailPattern(policy) ||
        oneOf(["_first_._last_", "_last_._first_", "_f__last_", "_first__l_"]) +
            oneOf(["", "", "", "_year_", "_number_"]) +
            "@" +
            oneOf(mailServers);
    return pattern
        .replace("_first_", first)
        .replace("_last_", last)
        .replace("_f_", first[0])
        .replace("_l_", last[0])
        .replace("_year_", number(1970, 2019))
        .replace("_number_", number(1, 1000));
};
const policyCompliantEmail = policy => (firstName, lastName) =>
    email(firstName, lastName, policy);
const username = (firstName, lastName, policy) => {
    const emailAddr = email(firstName, lastName, policy);
    return emailAddr.substr(0, emailAddr.indexOf("@"));
};
// Use 20 for the password hash for now, just to save screen space
const passwordHash = () => [...new Array(20)].map(() => hexDigit()).join("");

const dateInRange = (anchorDate, daysBack, daysForward) => {
    const baseDate = anchorDate ? new Date(anchorDate) : new Date();
    const date = new Date(
        baseDate.setDate(baseDate.getDate() + number(-daysBack, daysForward))
    );
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).substr(
        -2
    )}-${("0" + date.getDate()).substr(-2)}`;
};
const timeOfDay = () =>
    `${twoDigits(number(0, 23))}:${twoDigits(number(0, 59))}:${twoDigits(
        number(0, 59)
    )}`;

const twoDigits = num => ("0" + num).substr(-2);
const timestamp = (anchorDate, daysBack = 0, daysForward = 0) => {
    const datePart = dateInRange(anchorDate, daysBack, daysForward);
    const timePart = timeOfDay();
    return `${datePart} ${timePart}`;
};

const counter = from => {
    counter.lastFrom = from || counter.lastFrom || 1;
    return counter.lastFrom++;
};

const sequence = list => {
    if (list) {
        sequence.list = shuffle(list.slice(0));
    }
    if (sequence.list.length) {
        return sequence.list.pop();
    }
    throw new Error("Sequence run out of entires");
};

const ukCarRegistration = () => {
    const noIQZletters = "ABCDEFGHJKLMNOPRSTUVWXY".split("");
    return (
        oneOf(noIQZletters) +
        oneOf(noIQZletters) +
        twoDigits(oneOf([number(2, 29), number(51, 79)])) +
        " " +
        letter().toUpperCase() +
        letter().toUpperCase() +
        letter().toUpperCase()
    );
};

const carColor = () =>
    oneOf([
        "White",
        "Silver",
        "Black",
        "Grey",
        "Blue",
        "Red",
        "Brown",
        "Green"
    ]);

const carMake = () => oneOf(carMakes);

const table = (name, columns, numberOfRows, rules = {}) => {
    const result = {
        headers: [],
        data: [],
        name
    };

    result.headers = columns.map(header => ({ ...header, table: name }));
    const additionalRows = rules.add
        ? rules.add.map(() => number(0, numberOfRows - 1))
        : [-1];
    for (let i = 0; i < numberOfRows; ++i) {
        result.data.push(
            columns.reduce((acc, col, colIndex) => {
                let candidateValue;
                let foundDuplicates = false;
                let foundExcluded = false;
                uniqueAttemptNumber = 0;
                do {
                    uniqueAttemptNumber++;
                    if (uniqueAttemptNumber > 1000) {
                        throw new Error(
                            "Tried a 1000 times, no unique " + col.name
                        );
                    }
                    if (additionalRows.includes(i)) {
                        candidateValue =
                            variables[
                                rules.add[additionalRows.indexOf(i)].split(",")[
                                    colIndex
                                ]
                            ];
                    } else {
                        if (col.dependOn) {
                            if (col.dependOn.some(isNaN)) {
                                candidateValue = col.cb(...col.dependOn);
                            } else {
                                // It's a relative column offset
                                candidateValue = col.cb(
                                    ...col.dependOn.map(
                                        ofs => acc[colIndex + ofs]
                                    )
                                );
                            }
                        } else {
                            candidateValue = col.cb(
                                i === 0 ? col.seed : undefined
                            );
                        }

                        if (excludedValues.length) {
                            foundExcluded = excludedValues.includes(
                                candidateValue
                            );
                        }

                        if (col.unique) {
                            foundDuplicates = result.data.some(
                                row => row[colIndex] === candidateValue
                            );
                            if (rules.add) {
                                // Value should be equal to the answer column value
                                // since the answer is going to be added eventually
                                // TODO multiple rules.add
                                foundDuplicates = foundDuplicates; /*||
                  candidateValue ===
                    variables[rules.addAnswer.split(',')[colIndex]];*/
                            }
                        }
                    }
                } while (foundDuplicates || foundExcluded);

                return [...acc, candidateValue];
            }, [])
        );
    }

    // Rearrange columns
    columns.forEach((header, colIndex) => {
        if (header.finalIndex === undefined) {
            return;
        }
        result.data = result.data.map(row => {
            const colValue = row[colIndex];
            const newRow = [
                ...row.slice(0, colIndex),
                ...row.slice(colIndex + 1)
            ];
            newRow.splice(header.finalIndex, 0, colValue);
            return newRow;
        });
        const colToAdd = result.headers[colIndex];
        result.headers.splice(colIndex, 1);
        result.headers.splice(header.finalIndex, 0, colToAdd);
    });

    // Remove temporary columns
    result.headers.forEach((header, colIndex) => {
        if (!header.temporary) {
            return;
        }
        result.data = result.data.map(row => [
            ...row.slice(0, colIndex),
            ...row.slice(colIndex + 1)
        ]);
    });
    result.headers = result.headers.filter(h => !h.temporary);

    return result;
};

const addColumn = ({ headers, data, name }, col) => {
    const result = {
        headers: [],
        data: [],
        name
    };

    result.headers = [...headers, { ...col, table: name }];
    result.data = data.map(row => row.slice(0));

    for (let row = 0; row < data.length; ++row) {
        let candidateValue;
        let foundDuplicates = false;
        let foundExcluded = false;
        uniqueAttemptNumber = 0;
        do {
            uniqueAttemptNumber++;
            if (col.dependOn) {
                if (col.dependOn.some(isNaN)) {
                    candidateValue = col.cb(...col.dependOn);
                } else {
                    // It's a relative column offset
                    candidateValue = col.cb(
                        ...col.dependOn.map(
                            ofs => acc[result.data[i].length - 1 + ofs]
                        )
                    );
                }
            } else {
                candidateValue = col.cb(row === 0 ? col.seed : undefined);
            }

            if (excludedValues.length) {
                foundExcluded = excludedValues.includes(candidateValue);
            }

            if (col.unique) {
                foundDuplicates = result.data.some(
                    row => row[result.data[i].length - 1] === candidateValue
                );
            }
        } while (foundDuplicates || foundExcluded);

        result.data[row].push(candidateValue);
    }
    return result;
};

// Columns are assumed to be CamelCase by default
const styleColumns = tables => {
    const style = oneOf(["lower", "snakecase", "camelcase"]);
    tables.forEach(({ headers }) => {
        headers.forEach(header => {
            const { name } = header;
            if (name.indexOf("_") !== -1 || name[0].toUpperCase() !== name[0]) {
                throw new Error(
                    `Column name ${name} should specified as CamelCase by default`
                );
            }
            switch (style) {
                case "camelcase":
                    // Assume columns to be camel case by default
                    break;
                case "snakecase":
                    header.name = name
                        .split("")
                        .map(
                            (c, i) =>
                                (i > 0 && c >= "A" && c <= "Z" ? "_" : "") + c
                        )
                        .join("")
                        .toLowerCase();
                    break;

                case "lower":
                    header.name = name.toLowerCase();
                    break;
            }
        });
    });
};

const columnRunningId = (options = {}) => ({
    name: "Id",
    type: "number",
    cb: counter,
    seed: 1,
    content: "internalId",
    ...options
});
const columnGender = (options = {}) => ({
    name: oneOf(["Gender"]),
    type: "string",
    cb: () => oneOf([Gender.MALE, Gender.FEMALE]),
    content: "gender",
    ...options
});
const columnFirstName = (options = {}) => ({
    name: oneOf(["FirstName", "GivenName"]),
    type: "string",
    cb: firstName,
    content: "firstName",
    ...options
});
const columnLastName = () => ({
    name: oneOf(["LastName", "FamilyName", "Surname"]),
    type: "string",
    cb: lastName,
    content: "lastName"
});
const columnFullName = (options = {}) => ({
    name: oneOf(["Name", "FullName"]),
    type: "string",
    cb: fullName,
    content: "fullName",
    ...options
});
const columnEmail = (options = {}) => ({
    name: oneOf(["Email", "EmailAddress"]),
    type: "string",
    cb: !options.policy ? email : policyCompliantEmail(options.policy),
    unique: true,
    content: "email",
    ...options
});
const columnLastAccess = numberOfDaysBack => ({
    name: oneOf(["LastAccess"]),
    type: "string",
    cb: timestamp.bind(null, undefined, numberOfDaysBack),
    content: "timestamp"
});

const usersTable = (options = {}) => {
    const { numberOfRows = 100, numberOfDaysBack = 180 } = options;

    return table(
        "users",
        [
            columnRunningId({ isPrimaryKey: true }),
            columnGender(),
            ...oneOf([
                [
                    columnFirstName({ dependOn: [-1] }),
                    columnLastName(),
                    columnEmail({ dependOn: [-2, -1] })
                ],
                [
                    columnFullName({ dependOn: [-1] }),
                    columnEmail({ dependOn: [-1] })
                ]
            ]),
            columnLastAccess(numberOfDaysBack)
        ],
        numberOfRows
    );
};

const employeesTable = (options = {}) => {
    const {
        numberOfRows = 100,
        numberOfDaysBack = 180,
        emailPolicy = {
            pattern: "_first_._last_",
            server: "corp.com"
        }
    } = options;

    return table(
        "employees",
        [
            /* TODO columnEmployeeNumber */ columnRunningId({
                isPrimaryKey: true
            }),
            columnGender({ temporary: true }),
            ...oneOf([
                [
                    columnFirstName({ dependOn: [-1] }),
                    columnLastName(),
                    columnEmail({ dependOn: [-2, -1], policy: emailPolicy })
                ],
                [
                    columnFullName({ dependOn: [-1] }),
                    columnEmail({ dependOn: [-1], policy: emailPolicy })
                ]
            ]),
            columnLastAccess(numberOfDaysBack)
        ],
        numberOfRows
    );
};

const membersTable = () => {
    /* TODO */
};
const customersTable = () => {
    /* TODO */
};
const studentsTable = () => {
    /* TODO */
};
const mailingListTable = () => {
    /* TODO */
};

const departmentsTable = (departmentNames, options = {}) => {
    return table(
        "departments",
        [
            columnRunningId({ isPrimaryKey: true }),
            {
                name: oneOf(["DepartmentName", "Name", "Department"]),
                type: "string",
                cb: sequence,
                seed: departmentNames,
                content: "departmentName",
                ...options
            }
        ],
        departmentNames.length
    );
};

const companyInfo = () => {
    const suffix = oneOf([
        "International",
        "Consulting",
        "Holdings",
        "Global",
        "Limited",
        "PLC",
        "Consolidated",
        "Associates",
        "Inc"
    ]);
    const name =
        oneOf([
            lastName(),
            choose(letters, 3)
                .join("")
                .toUpperCase()
        ]) +
        " " +
        suffix;
    const server =
        name
            .toLowerCase()
            .split(" ")
            .shift() + oneOf([".com", ".net", ".org", ".biz"]);
    return {
        name,
        server
    };
};

const weightedSequence = (weightedValues, length) => {
    const total = weightedValues.reduce((acc, { weight }) => acc + weight, 0);
    const counts = weightedValues.map(({ value, weight }) => ({
        value,
        count: Math.ceil((weight * length) / total)
    }));
    return counts.reduce(
        (acc, { value, count }) => [
            ...acc,
            ...Object.keys([...new Array(count)]).map(() => value)
        ],
        []
    );
};

const corporateDb = (options = {}) => {
    const { numberOfEmployees = 1000 } = options;

    const company = companyInfo();
    // console.log(company);

    const optionalDepartments = [
        { name: ["Engineering", "RnD", "Development"], weight: 20 },
        { name: ["Ninjas", "Unicorns", "Rock stars"], weight: 1 },
        { name: ["Marketing", "Sales"], weight: 30 },
        { name: ["Infrastructure", "IT"], weight: 10 },
        { name: ["Accounting", "Finance"], weight: 10 },
        { name: ["Legal"], weight: 10 },
        { name: ["HR", "Human Resources", "Talent Acquisition"], weight: 10 },
        { name: ["Management"], weight: 5 },
        { name: ["Maintenance", "Facilities"], weight: 20 },
        { name: ["Call center", "Support"], weight: 40 }
    ];

    const structure = choose(
        optionalDepartments,
        number(3, optionalDepartments.length - 1)
    ).map(({ name, weight }) => ({ name: oneOf(name), weight }));

    const departments = departmentsTable(structure.map(dep => dep.name));

    structure.forEach(({ name }, i) => {
        // 0 = Department ID column
        // 1 = Department name column
        structure[i].value = departments.data
            .filter(row => row[1] === name)
            .shift()[0];
    });

    const employees = employeesTable({
        numberOfRows: numberOfEmployees,
        emailPolicy: {
            pattern: oneOf(["_f__last_", "_first_._last_", "_l__first_"]),
            server: company.server
        }
    });

    //console.log(weightedSequence(structure, numberOfEmployees));

    // *** Assign departments to employees ***
    const rankedEmployees = addColumn(employees, {
        name: oneOf(["Department", "DepartmentId"]),
        isForeignKey: true,
        references: "departments.primary",
        cb: sequence,
        seed: weightedSequence(structure, numberOfEmployees),
        content: "internalId"
    });

    company.db = [rankedEmployees, departments];
    styleColumns(company.db);
    return company;
};

const generateTable = ({ name, columns, numberOfRows, rules }) => {
    const columnDefinitions = columns.map(column => {
        const {
            type,
            dependOn,
            temporary = false,
            finalIndex,
            daysBack = 0,
            daysForward = 0,
            anchorDate,
            unique,
            isPrimaryKey,
            isForeignKey,
            name,
            single,
            plural,
            min,
            max
        } = column;

        const whitelistedColumn = {
            temporary,
            finalIndex,
            unique,
            dependOn,
            isPrimaryKey,
            isForeignKey
        };

        return (
            {
                gender: {
                    name: oneOf(name) || oneOf(["Gender"]),
                    single: sameOf(single) || sameOf(["gender"]),
                    plural: sameOf(plural) || sameOf(["genders"]),
                    type: "string",
                    cb: () => oneOf([Gender.MALE, Gender.FEMALE]),
                    content: "gender",
                    ...whitelistedColumn
                },
                "first name": {
                    name: oneOf(name) || oneOf(["FirstName", "GivenName"]),
                    single:
                        sameOf(single) || sameOf(["first name", "given name"]),
                    plural:
                        sameOf(plural) ||
                        sameOf(["first names", "given names"]),
                    type: "string",
                    cb: firstName,
                    content: "firstName",
                    ...whitelistedColumn
                },
                "last name": {
                    name:
                        oneOf(name) ||
                        oneOf(["LastName", "FamilyName", "Surname"]),
                    single:
                        sameOf(single) ||
                        sameOf(["last name", "family name", "surname"]),
                    plural:
                        sameOf(plural) ||
                        sameOf(["last names", "family names", "surnames"]),
                    type: "string",
                    cb: lastName,
                    content: "lastName",
                    ...whitelistedColumn
                },
                "full name": {
                    name: oneOf(name) || oneOf(["Name", "FullName"]),
                    single: sameOf(single) || sameOf(["name", "full name"]),
                    plural: sameOf(plural) || sameOf(["names", "full names"]),
                    type: "string",
                    cb: fullName,
                    content: "fullName",
                    ...whitelistedColumn
                },
                email: {
                    name: oneOf(name) || oneOf(["Email", "EmailAddress"]),
                    single:
                        sameOf(single) || sameOf(["email", "email address"]),
                    plural:
                        sameOf(plural) || sameOf(["emails", "email addresses"]),
                    type: "string",
                    cb: email,
                    content: "email",
                    ...whitelistedColumn
                },
                username: {
                    name: oneOf(name) || oneOf(["Username"]),
                    single: sameOf(single) || sameOf(["username"]),
                    plural: sameOf(plural) || sameOf(["usernames"]),
                    type: "string",
                    cb: username,
                    content: "username",
                    ...whitelistedColumn
                },
                passwordHash: {
                    name:
                        oneOf(name) ||
                        oneOf(["PasswordHash", "HashedPassword"]),
                    single:
                        sameOf(single) ||
                        sameOf(["password hash", "hashed password"]),
                    plural:
                        sameOf(plural) ||
                        sameOf(["password hashes", "hashed passwords"]),
                    type: "string",
                    cb: passwordHash,
                    content: "passwordHash",
                    ...whitelistedColumn
                },
                "uk car registration": {
                    name:
                        oneOf(name) ||
                        oneOf([
                            "CarRegistration",
                            "Registration",
                            "RegistrationNo"
                        ]),
                    single:
                        sameOf(single) ||
                        sameOf([
                            "car registration",
                            "registration",
                            "registration number"
                        ]),
                    plural:
                        sameOf(plural) ||
                        sameOf([
                            "car registrations",
                            "registrations",
                            "registration numbers"
                        ]),
                    type: "string",
                    cb: ukCarRegistration,
                    content: "registrationNo",
                    ...whitelistedColumn
                },
                "car color": {
                    name: oneOf(name) || oneOf(["Color"]),
                    single: sameOf(single) || sameOf(["color"]),
                    plural: sameOf(plural) || sameOf(["colors"]),
                    type: "string",
                    cb: carColor,
                    content: "carColor",
                    ...whitelistedColumn
                },
                "car make": {
                    name:
                        oneOf(name) ||
                        oneOf(["Make", "CarMake", "Type", "CarType"]),
                    single:
                        sameOf(single) ||
                        sameOf(["make", "car make", "type", "car type"]),
                    plural:
                        sameOf(plural) ||
                        sameOf(["makes", "car makes", "types", "car types"]),
                    type: "string",
                    cb: carMake,
                    content: "carMake",
                    ...whitelistedColumn
                },
                date: {
                    name: oneOf(name),
                    single: sameOf(single),
                    plural: sameOf(plural),
                    type: "date",
                    cb: dateInRange.bind(
                        null,
                        anchorDate,
                        daysBack,
                        daysForward
                    ),
                    content: "date",
                    ...whitelistedColumn
                },
                time: {
                    name: oneOf(name),
                    single: sameOf(single),
                    plural: sameOf(plural),
                    type: "time",
                    cb: dateInRange.bind(
                        null,
                        anchorDate,
                        daysBack,
                        daysForward
                    ),
                    content: "time",
                    ...whitelistedColumn
                },
                timestamp: {
                    name: oneOf(name),
                    single: sameOf(single),
                    plural: sameOf(plural),
                    type: "timestamp",
                    cb: timestamp.bind(null, anchorDate, daysBack, daysForward),
                    content: "timestamp",
                    ...whitelistedColumn
                },
                fullAddress: {
                    name: oneOf(name),
                    single: sameOf(single),
                    plural: sameOf(plural),
                    type: "string",
                    cb: fullAddress,
                    content: "fullAddress",
                    ...whitelistedColumn
                },
                number: {
                    name: oneOf(name),
                    single: sameOf(single),
                    plural: sameOf(plural),
                    type: "number",
                    cb: number.bind(null, min, max),
                    content: "number",
                    ...whitelistedColumn
                }
            }[type] ||
            (() => {
                throw new Error("Unknown type in table generation definition");
            })()
        );
    });

    return table(name, columnDefinitions, numberOfRows, rules);

    /*return table(
    'users',
    [
      columnRunningId({ isPrimaryKey: true }),
      columnGender(),
      ...oneOf([
        [
          columnFirstName({ dependOn: [-1] }),
          columnLastName(),
          columnEmail({ dependOn: [-2, -1] }),
        ],
        [columnFullName({ dependOn: [-1] }), columnEmail({ dependOn: [-1] })],
      ]),
      columnLastAccess(numberOfDaysBack),
    ],
    numberOfRows,
  );*/
};

const declareVariables = variableDefinions => {
    const varTable = generateTable({
        name: "variables",
        columns: variableDefinions,
        numberOfRows: 1
    });
    variables = {};
    varTable.headers.forEach(({ name: varName }, i) => {
        variables[varName] = varTable.data[0][i];
    });

    /* TODO REMOVE */ // console.table(JSON.stringify(variables, undefined, 4));
};

const variable = name => variables[name];

export {
    seed,
    CRC,
    exclude,
    excludeReset,
    number,
    digit,
    oneOf,
    choose,
    firstName,
    lastName,
    fullName,
    animal,
    street,
    counter,
    sequence,
    table,
    usersTable,
    employeesTable,
    corporateDb,
    generateTable,
    declareVariables,
    variable,
    encouragement
};

/*
seed('hello.');
console.log(
  toString(
    table(
      [
        { name: 'id', type: 'number', cb: counter, seed: 1 },
        { name: 'firstName', type: 'string', cb: firstName },
        { name: 'lastName', type: 'string', cb: lastName },
        { name: 'rank', type: 'number', cb: sequence, seed: [1, 2, 3, 4, 5] },
      ],
      5,
    ),
  ),
);
*/
