/*const family = {
  headers: [
    {name: 'id', type: 'number', isPrimaryKey: true},
    {name: 'firstName', type: 'string'},
    {name: 'lastName', type: 'string'},
    {name: 'age', type: 'number'},
  ],
  data: [
    [1, 'Doron', 'Linder', 41],
    [2, 'Liat', 'Linder', 38],
    [3, 'Ofer', 'Linder', 8],
    [4, 'Noa', 'Linder', 4],
    [5, 'Pili', 'Linder', 8],
  ],
};

const winners = {
  headers: [
    {name: 'id', type: 'number'},
    {name: 'name', type: 'string', isForeignKey: true},
    {name: 'rank', type: 'number'},
  ],
  data: [
    [1, 'Eric', 4],
    [2, 'Bart', 3],
    [3, 'Grover', 2],
    [4, 'Bart', 5],
    [5, 'Cookie Monster', 1],
    [6, 'Grover', 2],
  ],
};
*/

/* 
 Implement stable sorting to prevent the same table to be sorted twice
 and end up with two different results.
 
  Taken from https://medium.com/@fsufitch/is-javascript-array-sort-stable-46b90822543f
*/
Array.prototype.stableSort = function(cmp) {
  cmp = !!cmp
    ? cmp
    : (a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      };
  let stabilizedThis = this.map((el, index) => [el, index]);
  let stableCmp = (a, b) => {
    let order = cmp(a[0], b[0]);
    if (order != 0) return order;
    return a[1] - b[1];
  };
  stabilizedThis.sort(stableCmp);
  for (let i = 0; i < this.length; i++) {
    this[i] = stabilizedThis[i][0];
  }
  return this;
};

const getMaxColumnLengths = (headers, data, groupedData) => {
  if (data) {
    return data.reduce(
      (acc, row) => row.map((col, i) => Math.max(acc[i], ('' + col).length)),
      headers.map(column => column.name.length),
    );
  }
  if (groupedData) {
    return groupedData.reduce((groupAcc, group) => {
      const groupMaxLengths = group.reduce(
        (acc, row) => row.map((col, i) => Math.max(acc[i], ('' + col).length)),
        headers.map(column => column.name.length),
      );
      return groupMaxLengths.map((len, i) => Math.max(groupAcc[i], len));
    }, headers.map(column => column.name.length));
  }
};

const getHeaderIndex = (headers, headerName) => {
  // headerName can be both a column name or tableName.columnName
  // TODO alias support?
  const [tName, hName] =
    headerName.indexOf('.') !== -1
      ? headerName.split('.')
      : [undefined, headerName];

  const index = headers.indexOf(
    headers
      .filter(
        h =>
          h.name.toLowerCase() === hName.toLowerCase() &&
          (tName === undefined ||
            h.table.toLowerCase() === tName.toLowerCase()),
      )
      .shift(),
  );

  if (index === -1) {
    throw new Error(
      `There is no field named "${headerName}" in the resulting FROM table.\n`,
      // In the mobile version the user doesn't control the case anyway
      // Case does not matter in field names.`,
    );
  }

  return index;
};

const getHeaderType = (headers, headerName) => {
  const headerIndex = getHeaderIndex(headers, headerName);
  return headers[headerIndex].type;
};

export function headerContentPlural({ headers }, headerName) {
  const headerIndex = getHeaderIndex(headers, headerName);
  return headers[headerIndex].plural;
}

export function headerContentSingle({ headers }, headerName) {
  const headerIndex = getHeaderIndex(headers, headerName);
  return headers[headerIndex].single;
}

export function numberOfRows({ data }) {
  return data.length;
}

export function toString({ headers, data, groupedData }) {
  const maxLengths = getMaxColumnLengths(headers, data, groupedData);
  const totalLength =
    /* *3 since we add 2 spaces per header and 1 for the bar + 1 for the final bar */
    maxLengths.reduce((acc, x) => acc + x, 0) + headers.length * 3 + 1;
  const separator = ''.padStart(totalLength, '-');
  const headerLine =
    '| ' +
    headers
      .map((header, i) => header.name.padEnd(maxLengths[i], ' '))
      .join(' | ') +
    ' |';
  const dataLines = groupedData
    ? // Grouped format
      groupedData
        .map(group =>
          group
            .map(
              (row, i) =>
                '| ' +
                row
                  .map((colValue, i) => {
                    if (headers[i].type === 'number') {
                      return ('' + colValue).padStart(maxLengths[i], ' ');
                    } else {
                      return ('' + colValue).padEnd(maxLengths[i], ' ');
                    }
                  })
                  .join(' | ') +
                ' |',
            )
            .join('\n'),
        )
        .join('\n' + separator + '\n')
    : // Regular ungrouped format
      data
        .map(
          (row, i) =>
            '| ' +
            row
              .map((colValue, i) => {
                if (headers[i].type === 'number') {
                  return ('' + colValue).padStart(maxLengths[i], ' ');
                } else {
                  return ('' + colValue).padEnd(maxLengths[i], ' ');
                }
              })
              .join(' | ') +
            ' |',
        )
        .join('\n');

  return `${separator}\n${headerLine}\n${separator}\n${dataLines}\n${separator}\n`;
}

const count = (headers, data, column) => {
  if (!column) {
    throw new Error('Unsupported count without a *');
  }
  return data.length;
};

const sum = (headers, data, column) => {
  if (!column) {
    throw new Error('Sum without a column');
  }
  const headerIndex = getHeaderIndex(headers, column);
  return data.reduce(
    (acc, row) => acc + (isNaN(row[headerIndex]) ? 0 : row[headerIndex]),
    0,
  );
};

const avg = (headers, data, column) => {
  if (!column) {
    throw new Error('Avg without a column');
  }
  const headerIndex = getHeaderIndex(headers, column);
  return data.length === 0
    ? 0
    : data.reduce(
        (acc, row) => acc + (isNaN(row[headerIndex]) ? 0 : row[headerIndex]),
        0,
      ) / data.length;
};

const min = (headers, data, column) => {
  if (!column) {
    throw new Error('Min without a column');
  }
  const headerIndex = getHeaderIndex(headers, column);
  return data.reduce((acc, row) => {
    const value = row[headerIndex];
    return acc < value ? acc : value;
  }, data[0][headerIndex]);
};

const max = (headers, data, column) => {
  if (!column) {
    throw new Error('Max without a column');
  }
  const headerIndex = getHeaderIndex(headers, column);
  return data.reduce((acc, row) => {
    const value = row[headerIndex];
    return acc > value ? acc : value;
  }, data[0][headerIndex]);
};

function processProjectTree({ headers, data }, projectTree) {
  const result = {
    header: null,
    data: [],
    aggregated: false,
  };
  const { func, column, alias, table } = projectTree;
  if (!func) {
    if (column) {
      const headerIndex = getHeaderIndex(
        headers,
        table ? table + '.' + column : column,
      );
      result.header = { ...headers[headerIndex] };
      if (alias) {
        // TODO in the future, do we need to keep both name and alias?
        result.header = { ...result.header, name: alias };
      }
      result.data = data.map(row => row[headerIndex]);
      return result;
    }

    // At this point it should only be a number or a string
    // TODO can also be an expression
    const { type, value } = projectTree;
    if (type === 'number' || type === 'string') {
      return {
        header: {
          name: alias || '?column?',
          type,
        },
        data: value,
        aggregated: true,
      };
    }

    throw new Error(`No op, column or type in projectTree`);
  }

  const aggregateResult = (resultName, resultType, dataFunction) => ({
    header: {
      name: alias || resultName,
      type: resultType,
    },
    data: dataFunction(headers, data, column),
    aggregated: true,
  });

  switch (func) {
    case 'count':
      return aggregateResult('COUNT(*)', 'number', count);
    case 'sum':
      return aggregateResult(`SUM(${column})`, 'number', sum);
    case 'avg':
      return aggregateResult(`AVG(${column})`, 'number', avg);
    case 'min':
      return aggregateResult(
        `MIN(${column})`,
        getHeaderType(headers, column),
        min,
      );
    case 'max':
      return aggregateResult(
        `MAX(${column})`,
        getHeaderType(headers, column),
        max,
      );
    default:
      throw new Error(`Unknown func ${func} in projectTree`);
  }
}

export function project(
  { headers, data, groupedData, name: tableName } = { headers: [], data: [] },
  projectTreeArray,
) {
  const newHeaders = [];
  const newData = [];
  const effectiveProjectTreeArray = projectTreeArray.reduce(
    (acc, projectTree) => {
      const { func, column } = projectTree;
      if (!func && column === '*') {
        // Substitute with all columns
        headers.forEach(header => {
          acc.push({
            column: header.name /*.toLowerCase() */,
            // header.table inherits from the parents of the join
            // tableName is for stand alone tables with no join
            table: header.table || tableName,
          });
        });
      } else {
        acc.push(projectTree);
      }
      return acc;
    },
    [],
  );

  if (groupedData) {
    groupedData.forEach((group, i) => {
      let curRow = [];
      effectiveProjectTreeArray.forEach(projectTree => {
        const {
          header: newHeader,
          data: columnData,
          aggregated,
        } = processProjectTree({ headers, data: group }, projectTree);
        if (i === 0) {
          newHeaders.push(newHeader);
        }
        curRow.push(aggregated ? columnData : columnData[0]);
      });
      newData.push(curRow);
    });
  } else {
    let oneLineResult = false;

    effectiveProjectTreeArray.forEach(projectTree => {
      const {
        header: newHeader,
        data: columnData,
        aggregated,
      } = processProjectTree({ headers, data }, projectTree);
      newHeaders.push(newHeader);

      if (aggregated) {
        oneLineResult = true;
        // Trim to one line if only now found out
        newData.length = 1;
      }

      if (oneLineResult) {
        if (!newData[0]) {
          newData[0] = [];
        }
        newData[0].push(aggregated ? columnData : columnData[0]);
      } else {
        data.forEach((_, i) => {
          if (!newData[i]) {
            newData[i] = [];
          }
          newData[i].push(columnData[i]);
        });
      }
    });
  }

  return {
    headers: newHeaders,
    data: newData,
  };
}

const isAMatch = (headers, rowOrGroup, predicateTree) => {
  const { op, type, func, column, name, value, right, left } = predicateTree;
  const isGroup = Array.isArray(rowOrGroup[0]);
  if (!op) {
    if (type) {
      switch (type) {
        case 'string':
        case 'number':
        case 'list':
          return value;
        default:
          throw new Error(`Unknown type ${type} in predicateTree`);
      }
    }
    if (func) {
      if (!isGroup) {
        throw new Error(`Can not use aggregative ${func} in where clause`);
      }
      const group = rowOrGroup;

      switch (func) {
        case 'count':
          return count(headers, group, column);
        case 'sum':
          return sum(headers, group, column);
        case 'avg':
          return avg(headers, group, column);
        case 'min':
          return min(headers, group, column);
        case 'max':
          return max(headers, group, column);
        default:
          throw new Error(`Unknown function ${func} in predicateTree`);
      }
    }
    if (column) {
      const headerIndex = getHeaderIndex(headers, column);
      return (isGroup ? rowOrGroup[0] : rowOrGroup)[headerIndex];
    }
    if (!type && !func && !column) {
      throw new Error(
        `Should have either op, type, func or column in predicateTree`,
      );
    }
  }
  switch (op) {
    case '=':
      return (
        isAMatch(headers, rowOrGroup, predicateTree.left) ===
        isAMatch(headers, rowOrGroup, predicateTree.right)
      );
    case '!=':
      return (
        isAMatch(headers, rowOrGroup, predicateTree.left) !==
        isAMatch(headers, rowOrGroup, predicateTree.right)
      );
    case '<>':
      return (
        isAMatch(headers, rowOrGroup, predicateTree.left) !==
        isAMatch(headers, rowOrGroup, predicateTree.right)
      );
    case '<':
      return (
        isAMatch(headers, rowOrGroup, predicateTree.left) <
        isAMatch(headers, rowOrGroup, predicateTree.right)
      );
    case '>':
      return (
        isAMatch(headers, rowOrGroup, predicateTree.left) >
        isAMatch(headers, rowOrGroup, predicateTree.right)
      );
    case '<=':
      return (
        isAMatch(headers, rowOrGroup, predicateTree.left) <=
        isAMatch(headers, rowOrGroup, predicateTree.right)
      );
    case '>=':
      return (
        isAMatch(headers, rowOrGroup, predicateTree.left) >=
        isAMatch(headers, rowOrGroup, predicateTree.right)
      );
    case 'or':
      return (
        isAMatch(headers, rowOrGroup, predicateTree.left) ||
        isAMatch(headers, rowOrGroup, predicateTree.right)
      );
    case 'and':
      return (
        isAMatch(headers, rowOrGroup, predicateTree.left) &&
        isAMatch(headers, rowOrGroup, predicateTree.right)
      );
    case 'not':
      return !isAMatch(headers, rowOrGroup, predicateTree.left);
    case 'in': {
      let list = isAMatch(headers, rowOrGroup, predicateTree.right);
      return list.includes(isAMatch(headers, rowOrGroup, predicateTree.left));
    }
    case 'not in': {
      let list = isAMatch(headers, rowOrGroup, predicateTree.right);
      return !list.includes(isAMatch(headers, rowOrGroup, predicateTree.left));
    }
    case '(': {
      return isAMatch(headers, rowOrGroup, predicateTree.left);
    }
    default:
      throw new Error(`Unknown operator ${op} in predicateTree`);
  }
};

export function filter({ headers, data }, predicateTree) {
  return {
    headers,
    data: data.filter(row => isAMatch(headers, row, predicateTree)),
  };
}

export function join(type, left, right, predicateTree) {
  const { headers: leftHeaders, name: leftName, data: leftData } = left;
  const { headers: rightHeaders, name: rightName, data: rightData } = right;

  const headers = [
    ...leftHeaders.map(header => ({ ...header, table: leftName })),
    ...rightHeaders.map(header => ({ ...header, table: rightName })),
  ];

  const data = [];
  if (type === 'inner') {
    leftData.forEach(lData => {
      rightData.forEach(rData => {
        if (isAMatch(headers, [...lData, ...rData], predicateTree)) {
          data.push([...lData, ...rData]);
        }
      });
    });
  }

  return {
    headers,
    data,
  };
}

export function distinct({ headers, data }) {
  const rowNumbersColIndex = headers.reduce(
    (acc, { name }, i) => (name === '#' ? i : acc),
    -1,
  );
  const SPECIAL_HACK_CHAR = '|';
  data.forEach(row => {
    if (row.join('').indexOf(SPECIAL_HACK_CHAR) !== -1) {
      throw new Error(
        `Can not run distinct, row contains a ${SPECIAL_HACK_CHAR}`,
      );
    }
  });
  let newData;
  if (rowNumbersColIndex === -1) {
    newData = data.map(row => row.join(SPECIAL_HACK_CHAR)).stableSort();
  } else {
    newData = data.map(row => {
      // remove original row num for distinct to work and return it afterwards
      const rowNum = row[rowNumbersColIndex];
      const newRow = row
        .filter((_, i) => i !== rowNumbersColIndex)
        .join(SPECIAL_HACK_CHAR);
      return {
        row: newRow,
        rowNum,
      };
    });
    newData.stableSort((a, b) => {
      if (a.row < b.row) return -1;
      if (a.row > b.row) return 1;
      return 0;
    });
  }

  let lastRow = '';
  newData = newData
    .reduce((acc, row) => {
      if (
        (rowNumbersColIndex === -1 && row !== lastRow) ||
        (rowNumbersColIndex !== -1 && row.row !== lastRow.row)
      ) {
        acc.push(row);
        lastRow = row;
      }
      return acc;
    }, [])
    .map(row => {
      const newRow = (row.row || row).split(SPECIAL_HACK_CHAR);
      if (row.rowNum !== undefined) {
        newRow.splice(rowNumbersColIndex, 0, row.rowNum);
      }
      return newRow;
    });

  return {
    headers,
    data: newData,
  };
}

const orderArrayToPredicate = (headers, orderArray) => {
  return function(row, otherRow) {
    return orderArray.reduce((result, order) => {
      if (result !== 0) {
        return result;
      }
      const { column, desc, type, value: columnNumber } = order;
      if (column || type === 'number') {
        const headerIndex =
          type === 'number'
            ? columnNumber - 1
            : getHeaderIndex(headers, column);
        const value = row[headerIndex];
        const otherValue = otherRow[headerIndex];
        if (value === otherValue) {
          return 0;
        }
        if (value < otherValue) {
          return desc ? 1 : -1;
        }
        return desc ? -1 : 1;
      }
      throw new Error(JSON.stringify(order));
      throw new Error('Missing column name for order by');
    }, 0);
  };
};

export function orderBy({ headers, data }, orderArray) {
  const newData = data.slice(0);
  if (orderArray.length) {
    newData.stableSort(orderArrayToPredicate(headers, orderArray));
  }
  return {
    headers,
    data: newData,
  };
}

export function limit({ headers, data }, count) {
  if (count < 0) {
    throw new Error('Limit can not be negative');
  }
  return {
    headers,
    data: count === 0 ? data : data.slice(0, count),
  };
}

export function groupBy({ headers, data }, columns) {
  const sortedData = orderBy({ headers, data }, columns).data;
  const groupedData = [];
  let curGroup = -1;
  sortedData.forEach((row, i) => {
    let isNewGroup = false;
    if (i === 0) {
      isNewGroup = true;
    } else {
      const prevRow = sortedData[i - 1];
      isNewGroup = columns.reduce((isNew, col) => {
        if (isNew) {
          return true;
        }
        const headerIndex = getHeaderIndex(headers, col.column);
        return row[headerIndex] !== prevRow[headerIndex];
      }, isNewGroup);
    }
    if (isNewGroup) {
      curGroup++;
      groupedData.push([]);
    }
    groupedData[curGroup].push(row);
  });

  return {
    headers,
    groupedData,
  };
}

export function having({ headers, groupedData }, predicateTree) {
  return {
    headers,
    groupedData: groupedData.filter(group =>
      isAMatch(headers, group, predicateTree),
    ),
  };
}

const removeFuncParams = str =>
  str.indexOf('(') === -1 ? str : str.substring(0, str.indexOf('(')).trim();

const center = (str, length) =>
  `${''.padStart(
    Math.floor((length - str.length) / 2),
    ' ',
  )}${str}${''.padStart(Math.ceil((length - str.length) / 2), ' ')}`;

export function toPostgresString({
  headers: detailedHeaders,
  data,
  groupedData,
}) {
  const headers = detailedHeaders.map(header => ({
    ...header,
    name: removeFuncParams(header.name.toLowerCase()),
  }));
  const maxLengths = getMaxColumnLengths(headers, data, groupedData);
  const separator = headers
    .map((header, i) => ''.padEnd(maxLengths[i] + 2, '-'))
    .join('+');

  const headerLine =
    ' ' +
    headers
      .map((header, i) => center(header.name.toLowerCase(), maxLengths[i]))
      .join(' | ') +
    ' ';
  const dataLines = data
    .map(
      (row, i) =>
        ' ' +
        row
          .map((colValue, i) => {
            if (headers[i].type === 'number') {
              return ('' + colValue).padStart(maxLengths[i], ' ');
            } else {
              return ('' + colValue).padEnd(maxLengths[i], ' ');
            }
          })
          .join(' | ')
          .trimEnd(),
    )
    .join('\n');

  const rowCount = `(${data.length} row${data.length === 1 ? '' : 's'})`;

  return `${headerLine}\n${separator}\n${dataLines}${
    data.length ? '\n' : ''
  }${rowCount}\n`;
}

export function toERD({ headers, name: tableName }, withShortcuts = false) {
  return `
    <ul class="table">
      <li class="table-header" data-field="${tableName}.*">${
    withShortcuts ? '<span class="table-star">*</span>' : ''
  }<span class="table-name">${tableName}</span>${
    withShortcuts
      ? `<span class="table-alias">${tableName[0].toUpperCase()}</span>`
      : ''
  }</li>
      ${headers
        .map(
          ({ name, isPrimaryKey, isForeignKey }) => `
          <li
            class="${[
              'table-row',
              isPrimaryKey ? 'primary-key' : '',
              isForeignKey ? 'foreign-key' : '',
            ].join(' ')}"
            data-field="${tableName}.${name}"
          >${name}</li>`,
        )
        .join('')}
    </ul>`;
}

export function toHTML({ headers, data }) {
  return `
    <table>
      <thead>
        <tr>
          ${headers
            .map(
              ({ name }, i) =>
                `<th scope="col">${name /*.toLowerCase()*/}</th>`,
            )
            .join('')}
        </tr>
      </thead>
      <tbody>
          ${data
            .map(
              (row, i) =>
                `<tr>${row
                  .map(
                    (value, col) => `
                      <td
                        class="content-${headers[col].content}"
                        data-content="${headers[col].content}"
                      >${
                        headers[col].content === 'email'
                          ? `
                      <span class="email-wrapper">
                        ${value}
                      </span>
                      `
                          : value
                      }</td>`,
                  )
                  .join('')}</tr>`,
            )
            .join('')}
      </tbody>
    </table>`;
}

export function equivalent(candidate, expected) {
  const { headers, data } = candidate;
  const { headers: expectedHeaders, data: expectedData } = expected;

  // Headers should be the same name, type and content.
  // Ignore primary and foreign keys

  let headersMatch = true;
  let dataMatch = true;
  let reason;
  let sameOrder = true;
  let containsDuplicates = false;
  let unexpectedRows = false;

  headers.forEach(({ name, type, content }) => {
    const expectedHeader = expectedHeaders.find(header => header.name === name);
    if (!expectedHeader) {
      headersMatch = false;
      reason = reason || `Unexpected column ${name}`;
      return;
    }

    if (type !== expectedHeader.type) {
      headersMatch = false;
      reason = reason || `Expected ${name}'s type to be ${type}`;
      return;
    }

    if (content !== expectedHeader.content) {
      headersMatch = false;
      reason = reason || `Expected ${name} to contain ${content}`;
      return;
    }
  });

  expectedHeaders.forEach(({ name, type, content }) => {
    const header = headers.find(expectedHeader => expectedHeader.name === name);
    if (!header) {
      headersMatch = false;
      reason = reason || `Missing column ${name}`;
    }
  });

  if (!headersMatch) {
    return {
      result: false,
      reason,
    };
  }

  // Create header mapping for data check
  const headerMapping = [];
  headers.forEach(({ name }) => {
    expectedHeaders.forEach(({ name: expectedName }, i) => {
      if (name === expectedName) {
        headerMapping.push(i);
      }
    });
  });

  // Transform candidate data to be the same header order as
  // the expected data
  const candidateData = data.map(row => {
    const newRow = [];
    row.forEach((item, i) => (newRow[headerMapping[i]] = item));
    return newRow;
  });

  // Check for same order
  sameOrder = candidateData.reduce((acc, row, i) => {
    if (!acc) {
      return false;
    }
    // Order still kept, check current row
    let same = true;
    row.forEach((item, j) => {
      same = same && i < expectedData.length && item === expectedData[i][j];
    });
    return same;
  }, true);

  // Data should be the same

  const matchingIndices = {};
  candidateData.forEach(candidateRow => {
    const matchingIndex = expectedData.reduce((acc, row, i) => {
      if (acc > -1) {
        return acc;
      }
      if (
        row.reduce((same, item, j) => same && item === candidateRow[j], true)
      ) {
        if (matchingIndices[i]) {
          // One row should only match one row in the result
          containsDuplicates = true;
          return -1;
        }
        matchingIndices[i] = true;
        return i;
      }
      return -1;
    }, -1);

    if (matchingIndex === -1) {
      // Wait up with the return to check if unexpected rows
      // is due to duplicates
      unexpectedRows = true;
    }
  });

  if (unexpectedRows) {
    return {
      result: false,
      reason: 'Unexpected rows',
      containsDuplicates,
    };
  }

  if (expectedData.length != candidateData.length) {
    return {
      result: false,
      containsDuplicates,
      reason: 'Missing rows',
    };
  }

  return {
    result: true,
    sameOrder,
  };
}

export function addRowNumbers({ headers, data, name }) {
  const newHeaders = headers.map(header => ({ ...header }));
  newHeaders.unshift({ name: '#', type: 'number' });
  const newData = data.map((row, i) => [i, ...row]);

  return {
    name,
    headers: newHeaders,
    data: newData,
  };
}

export function extractRowNumbers({ headers, data }) {
  const { data: rowNumbers } = project({ headers, data }, [{ column: '#' }]);
  return rowNumbers.map(row => row[0]);
}

export function removeRowNumbers({ headers, data, name }) {
  const rowNumbersColIndex = getHeaderIndex(headers, '#');
  const newHeaders = headers
    .map(header => ({ ...header }))
    .filter(header => header.name != '#');
  const newData = data.map((row, i) => [
    ...row.slice(0, rowNumbersColIndex),
    ...row.slice(rowNumbersColIndex + 1),
  ]);

  return {
    name,
    headers: newHeaders,
    data: newData,
  };
}

export function values({ headers, data }, headerName) {
  const { data: rowNumbers } = project({ headers, data }, [
    { column: headerName },
  ]);
  return rowNumbers.map(row => row[0]);
}

/*console.log(
  toString(
    project(
      having(
        groupBy(winners, [
          {
            column: 'name',
          },
        ]),
        {
          op: 'or',
          left: {
            op: '<=',
            left: { func: 'min', column: 'rank' },
            right: { type: 'number', value: 3 },
          },
          right: {
            op: '=',
            left: { type: 'column', name: 'name' },
            right: { type: 'string', value: 'Eric' },
          },
        },
      ),
      [{ column: 'name' }, { func: 'min', column: 'rank' }],
    ),
  ),
  );*/
