import createAuth0Client from "@auth0/auth0-spa-js";

let auth0 = null;
let currentUser = null;

export const login = async () => {
    await auth0.loginWithRedirect({
        redirect_uri: window.location.origin
    });
};

export const logout = () => {
    auth0.logout({
        returnTo: window.location.origin
    });
};

const isInProcess = () => {
    if (currentUser) {
        return false;
    }
    const query = window.location.search;
    if (query.includes("code=") && query.includes("state=")) {
        return true;
    }
    // TODO might not work with secure cookies
    if (document.cookie.includes("auth0.is.authenticated=true")) {
        return true;
    }
    return false;
};

// Returns undefined if the user is in the process of logging in
// Returns null if there is no user
// Otherwise, returns an object
export const getUser = () => (isInProcess() ? undefined : currentUser);

export const setLoggedInUser = (email, accessToken) => {
    currentUser = {
        email,
        accessToken
    };
    setLoggedInUser.updateCallback();
};

export const initialise = async (updateCallback = () => {}) => {
    setLoggedInUser.updateCallback = updateCallback;
    try {
        // If the cookie is set, we want to show logging in overlay before
        // starting the calls to the server
        updateCallback();
        // Originally configuration was fetched from server but it takes
        // 2 seconds on a slow 3g connection and it's not a secret and
        // cache will be busted if changed on prod
        auth0 = await createAuth0Client({
            domain: "dev-9ax3-qo8.eu.auth0.com",
            client_id: "Qg1WDuGuayE16bJIRBDByY3wZh4FODnk",
            audience: "https://sqlpd.com/api"
        });

        let isAuthenticated = await auth0.isAuthenticated();
        if (!isAuthenticated) {
            const query = window.location.search;
            if (query.includes("code=") && query.includes("state=")) {
                // Process the login state
                await auth0.handleRedirectCallback();

                // Use replaceState to redirect the user away and remove the querystring parameters
                window.history.replaceState({}, document.title, "/");
                isAuthenticated = true;
            }
        }

        if (isAuthenticated) {
            updateCallback();
            const accessToken = await auth0.getTokenSilently();
            const user = await auth0.getUser();
            if (!user) {
                currentUser = null;
            } else {
                currentUser = {
                    email: user.email,
                    accessToken
                };
            }
            updateCallback();
        }
    } catch (error) {
        console.log(error);
    }
};
