import { execute, tableERD, resultsHTML, isEmpty } from "./sql/sql";
import generateMission from "./mission";
import queryAutocorrect from "./autocorrect";
import {
    login,
    logout,
    getUser as getAuthUser,
    initialise as initialiseAuth
} from "./interfaces/auth0_client";
import { getUser, submitAnswer, submitTestSolution } from "./interfaces/api";
import { CRC, encouragement } from "./generator/generator";

import "./vh-adjustment";

let cursor = document.querySelector(".cursor");
let query = document.querySelector(".query");
let placeholder = document.querySelector(".placeholder");
const resultsTabRadio = document.querySelector("#show-results-tab");
const columnHeaders = document.querySelector(".column-headers");
const rowsData = document.querySelector(".rows-data");
const sqlErrorMessage = document.querySelector(".sql-error-message");
const showErrorMessage = document.querySelector("#show-sql-error-message");
const showNoResultsYetMessage = document.querySelector(
    "#show-no-results-yet-message"
);
const showEmptyResultMessage = document.querySelector(
    "#show-empty-result-message"
);
const resultsScollableContainer = document.querySelector(
    ".scrollable-container"
);
const currentTable = document.querySelector(".current-table");
const showTagOptions = document.querySelector("#show-tag-options");
const selectedValue = document.querySelector(".selected-value");
const showUpdatedStatement = document.querySelector("#show-updated-statement");
const updatedStatement = document.querySelector(".updated-statement > p");
const showMissionTab = document.querySelector("#show-mission-tab");
const showBuilderTab = document.querySelector("#show-builder-tab");
const showLoginSection = document.querySelector("#show-login-section");
const showSendingModal = document.querySelector("#show-sending-modal");
const showNoSendingModal = document.querySelector("#show-no-sending-modal");
const showRankTestSendingModal = document.querySelector(
    "#show-rank-test-sending-modal"
);
const showRankTestUnknown = document.querySelector("#show-rank-test-unknown");
const showKeyboardErd = document.querySelector("#show-keyboard-erd");
const showStillInDevelopment = document.querySelector(
    "#show-still-in-development"
);
const missionBriefs = document.querySelectorAll(".mission-brief");
const missionInstructions = document.querySelector(".mission-instructions");
const tagOptions = document.querySelector(".tag-options > label");
const keyboardBrief = document.querySelector(".keyboard-brief");
const showResultsInstructions = document.querySelector(
    "#show-results-instructions"
);
const showSolvedStamp = document.querySelector("#show-solved-stamp");
const showRetryStamp = document.querySelector("#show-retry-stamp");
const retryMessage = document.querySelector(".retry-message");
const declineMessage = document.querySelector(".decline-message");
const approveMessage = document.querySelector(".approve-message");
const approvedRank = document.querySelector(".approved-rank");
const solvedMessage = document.querySelector(".solved-message");
const casesToNextRank = document.querySelector(".cases-to-next-rank");
const buySolvedCases = document.querySelector("#buy-solved-cases");
const accountRank = document.querySelector(".account-rank");
const showAdvanceToNextCase = document.querySelector(
    "#show-advance-to-next-case"
);
const showTakeRankTest = document.querySelector("#show-take-rank-test");
const showEndOfFreeTrial = document.querySelector("#show-end-of-free-trial");
const showRankTestSection = document.querySelector("#show-rank-test-section");
const showRankTestSuccess = document.querySelector("#show-rank-test-success");
const showRankTestFailure = document.querySelector("#show-rank-test-failure");
const approvedQualifiedNote = document.querySelector(
    ".approved-qualified-note"
);
const showWorkspaceSection = document.querySelector("#show-workspace-section");
const showLoginOptions = document.querySelector("#show-login-options");
const showLogoutOptions = document.querySelector("#show-logout-options");
const showProfileLoginOptions = document.querySelector(
    "#show-profile-login-options"
);
const showProfileLogoutOptions = document.querySelector(
    "#show-profile-logout-options"
);
const showLoggingIn = document.querySelector("#show-logging-in");
const showLoggingOut = document.querySelector("#show-logging-out");
const showBuyALicense = document.querySelector("#show-buy-a-license");

const loginLink = document.querySelector("#show-logout-options");
const accountEmailSpans = document.querySelectorAll(".account-email");

// 8629 is the enter symbol in textContent instead of a <br>
const whitespaceRegExp = new RegExp(
    `[\\s\\r\\n${String.fromCodePoint(8629)}]+`,
    "g"
);

let db;
let checkAnswer;
let missionType;
let resultsTable;
let missionLog;

let profile = {
    rank: "SQL Intern",
    qualifiedRank: "SQL Intern",
    solved: 0,
    casesLeftToNextRank: 11,
    missionAttributes: ["table", "whole"]
};

let timesToShowInstructions = 2;
let seenStillInDevelopmentMessage = false;

const updateProfile = (newProfile) => {
    [
        "rank",
        "qualifiedRank",
        "solved",
        "missionAttributes",
        "casesLeftToNextRank",
        "missionSeed",
        "rankTest",
        "reachedDevelopmentLimit"
    ].forEach((field) => {
        profile[field] = newProfile[field];
    });
    buySolvedCases.value = profile.solved;
};

const waitForXSeconds = (x) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, x * 1000);
    });

function scrollToElement(element) {
    if (element.scrollIntoViewIfNeeded) {
        element.scrollIntoViewIfNeeded(true);
    } else {
        element.scrollIntoView();
    }
}

function reset() {
    showLoggingIn.checked = false;
    showLoggingOut.checked = false;
    showLoginSection.checked = true;
    showMissionTab.checked = true;
    showTagOptions.checked = false;
    showNoSendingModal.checked = true;
    showUpdatedStatement.checked = false;
    showErrorMessage.checked = false;
    showResultsInstructions.checked = true;
    showBuyALicense.checked = false;
}

function updateMission() {
    document.body.classList.remove("brief-completed");
    document.body.classList.remove("mission-fill-in");
    document.body.classList.remove("mission-whole-table");
    missionLog = [];
    [...query.querySelectorAll(".query-part")].forEach((e) => {
        query.removeChild(e);
    });
    showNoResultsYetMessage.checked = true;
    showEmptyResultMessage.checked = false;
    showKeyboardErd.checked = true;
    columnHeaders.innerHTML = "";
    rowsData.innerHTML = "";
    keyboardBrief.innerHTML = "";

    const mission = generateMission(profile /*, 'Sql Detective' */);
    db = mission.db;
    missionType = mission.type;
    document.body.classList.add(`mission-${mission.type}`);
    checkAnswer = mission.checkAnswer;
    const { brief, statement, values, instructions } = mission;

    accountRank.innerHTML = profile.rank;
    casesToNextRank.innerHTML = profile.reachedDevelopmentLimit
        ? "-- PRACTICE MODE --"
        : `${profile.casesLeftToNextRank} more case${
              profile.casesLeftToNextRank > 1 ? "s" : ""
          } to next rank`;

    currentTable.innerHTML = tableERD(db, db[0].name);
    missionBriefs.forEach(
        (elem) =>
            (elem.innerHTML = brief.reduce((html, part, i) => {
                if (i % 2 === 0) {
                    return html + part;
                }

                return (
                    html +
                    ` <span
          class="statement-placeholder"
          data-tag="${part}"
        >${part}</span> `
                );
            }, ""))
    );

    missionInstructions.innerHTML = instructions;
    missionInstructions.style.display =
        timesToShowInstructions > 0 ? "block" : "none";
    if (timesToShowInstructions > 0) {
        timesToShowInstructions--;
    }

    const blanks = brief.filter((_, i) => i % 2 !== 0);

    updatedStatement.innerHTML = blanks
        .map(
            (part) => `${part}: <span
        class="statement-placeholder"
        data-tag="${part}"
      >${part}</span>`
        )
        .join("<br />");

    [...blanks /*, 'Save value to brief'*/]
        .map((tag) => {
            const tagOperation = document.createElement("div");
            tagOperation.className = "tag-operation";
            tagOperation.setAttribute("data-tag", tag);
            tagOperation.innerText = tag;
            return tagOperation;
        })
        .forEach((div) => tagOptions.insertAdjacentElement("beforebegin", div));

    values
        .map((value) => {
            const keyboardKey = document.createElement("div");
            keyboardKey.className = "keyboard-key brief-value";
            keyboardKey.innerText = value;
            return keyboardKey;
        })
        .forEach((div) => keyboardBrief.appendChild(div));
}

function updateRankTest() {
    const test = profile.rankTest;
    const question = document.querySelector(".rank-test-question");
    const optionsList = document.querySelector(".rank-test-options-list");
    // TODO support more than one question
    const currentQuestion = test[0];

    optionsList.innerHTML = currentQuestion.options
        .map(
            ({ option }) => `
        <li>
          <label class="button rank-test-option">${option}</label>
        </li>
      `
        )
        .join("");

    question.innerText = currentQuestion.question;
}

function getTargetElement(e) {
    var targ;

    if (!e) e = window.event;
    targ = e.target ? e.target : e.srcElement;

    if (targ.nodeType == 3)
        // defeat Safari bug
        targ = targ.parentNode;
    return targ;
}

document.addEventListener("keydown", (e) => {
    if (e.keyCode !== 8) {
        return true;
    }
    const { tagName, type } = getTargetElement(e) || {};
    const dontBlock =
        (tagName || "").toUpperCase() === "INPUT" &&
        (type || "").toUpperCase() !== "CHECKBOX";
    if (!dontBlock && e.preventDefault && e.stopImmediatePropagation) {
        e.preventDefault();
        e.stopImmediatePropagation();
    }
    return !dontBlock;
});

document.addEventListener("click", (e) => {
    const target = getTargetElement(e);
    const classes = target.classList;
    const prevSelection = document.querySelector(".selected-cell");

    if (classes.contains("hide-tag-options")) {
        if (prevSelection) {
            prevSelection.classList.remove("selected-cell");
            prevSelection.parentNode.classList.remove("selected-row");
        }
        showTagOptions.checked = false;
        showUpdatedStatement.checked = false;
    } else if (classes.contains("brief-value")) {
        const span = document.createElement("span");
        span.className = "query-part";
        span.setAttribute("data-type", "value");
        span.innerHTML = target.innerHTML + " ";
        query.insertBefore(span, cursor);
        scrollToElement(span);
    } else if (
        classes.contains("keyboard-key") ||
        classes.contains("table-star")
    ) {
        const span = document.createElement("span");
        span.className = "query-part";
        if (classes.contains("query-clause-start")) {
            span.className += " query-clause-start";
        }
        if (classes.contains("sort-order")) {
            span.setAttribute("data-type", "sort-order");
        }
        span.innerHTML = target.innerHTML + " ";
        query.insertBefore(span, cursor);
        scrollToElement(span);
    } else if (
        classes.contains("table-name") ||
        classes.contains("table-alias") ||
        classes.contains("table-row")
    ) {
        const span = document.createElement("span");
        span.className = "query-part";
        if (classes.contains("table-name") || classes.contains("table-alias")) {
            span.setAttribute("data-type", "table");
        } else if (classes.contains("table-row")) {
            span.setAttribute("data-type", "field");
        }
        span.innerHTML = target.innerHTML + " ";
        query.insertBefore(span, cursor);
        scrollToElement(span);
    } else if (classes.contains("semicolon")) {
        const span = document.createElement("span");
        span.className = "query-part";
        span.setAttribute("data-type", "semicolon");
        span.innerHTML = "; ";
        query.insertBefore(span, cursor);
        scrollToElement(span);
    } else if (classes.contains("query-part")) {
        cursor = query.removeChild(cursor);
        query.insertBefore(cursor, target.nextSibling);
        scrollToElement(cursor);
    } else if (classes.contains("query") && !placeholder.parentNode) {
        cursor = query.removeChild(cursor);
        query.appendChild(cursor);
        scrollToElement(cursor);
    } else if (classes.contains("backspace")) {
        const prev = cursor.previousSibling;
        if (prev) {
            query.removeChild(prev);
        }
        scrollToElement(cursor);
    } else if (classes.contains("clear-all")) {
        cursor = query.removeChild(cursor);
        query.innerHTML = "";
        query.appendChild(cursor);
        showErrorMessage.checked = false;
    } else if (classes.contains("execute")) {
        const sqlStatement = query.textContent
            .replace(placeholder.textContent, "")
            .replace(whitespaceRegExp, " ")
            .trim();
        missionLog.push(sqlStatement);
        try {
            if (!sqlStatement.startsWith("SELECT")) {
                throw new Error(
                    `For now, start with <span class='code'>SELECT</span>.
                    Need help? Check the Guide <span class="guide-tab-icon">&nbsp;</span> tab!`
                );
            }
            if (!sqlStatement.endsWith(";")) {
                throw new Error(
                    `SQL statements must end with a <span class='code'>;</span> (semicolon).`
                );
            }
            if (sqlStatement.indexOf(";") !== sqlStatement.lastIndexOf(";")) {
                throw new Error(
                    `Executing multiple statements is not supported. Make sure to have only one <span class='code'>;</span> (semicolon) at the end of the statement.`
                );
            }
            showErrorMessage.checked = false;
            resultsTable = execute(db, sqlStatement);
            showEmptyResultMessage.checked = isEmpty(resultsTable);
            if (isEmpty(resultsTable)) {
                columnHeaders.innerHTML = "";
                rowsData.innerHTML = "";
            } else {
                const { thead, tbody } = resultsHTML(resultsTable);
                columnHeaders.innerHTML = "<thead>" + thead + "</thead>";

                // Add the header row to the body in a collapsed state
                // to be taken into account in column widths

                rowsData.innerHTML = "<tbody>" + thead + tbody + "</tbody>";
            }
            showNoResultsYetMessage.checked = false;
            showTagOptions.checked = false;
            showUpdatedStatement.checked = false;
            resultsTabRadio.checked = true;
            resultsScollableContainer.scrollTo(0, 0);

            if (!isEmpty(resultsTable)) {
                columnHeaders.style.width = rowsData.offsetWidth + "px";
                const rowHeaders = rowsData.querySelectorAll("th");
                const headers = columnHeaders.querySelectorAll("th");
                headers.forEach(
                    (h, i) => (h.style.width = rowHeaders[i].offsetWidth + "px")
                );
            }

            missionLog[missionLog.length - 1] =
                "Q " + missionLog[missionLog.length - 1];
        } catch (e) {
            missionLog[missionLog.length - 1] =
                "E " + missionLog[missionLog.length - 1];
            sqlErrorMessage.innerHTML = e.message;
            showErrorMessage.checked = true;
        }
    } else if (
        target.className.includes("content-") &&
        missionType === "fill-in"
    ) {
        if (prevSelection) {
            prevSelection.classList.remove("selected-cell");
            prevSelection.parentNode.classList.remove("selected-row");
        }
        target.classList.add("selected-cell");
        target.parentNode.classList.add("selected-row");
        selectedValue.innerText = target.innerText;
        showTagOptions.checked = true;
        if (target.scrollIntoViewIfNeeded) {
            target.scrollIntoViewIfNeeded(true);
        }
    } else if (target.className.includes("tag-operation")) {
        const tag = target.getAttribute("data-tag");
        const selectedValue =
            document.querySelector(".selected-cell").innerText;

        const statementClaim = document.querySelector(
            `.mission-brief [data-tag='${tag}']`
        );
        statementClaim.innerText = selectedValue;
        statementClaim.className = "statement-claim";
        const updateStatementClaim = document.querySelector(
            `.updated-statement [data-tag='${tag}']`
        );
        updateStatementClaim.innerText = selectedValue;
        updateStatementClaim.className = "statement-claim";

        const briefComplete = !missionBriefs[0].querySelector(
            ".statement-placeholder"
        );
        if (briefComplete) {
            document.body.classList.add("brief-completed");
        }

        showUpdatedStatement.checked = true;
        showResultsInstructions.checked = false;
    } else if (
        target.classList.contains("mission-submit") ||
        target.classList.contains("table-submit")
    ) {
        let submission;
        if (showMissionTab.checked) {
            // Submit from brief tab, it's fill in mission
            submission = [
                ...document.getElementsByClassName("statement-claim")
            ].reduce((acc, e) => {
                return { ...acc, [e.getAttribute("data-tag")]: e.innerText };
            }, {});
        } else {
            // Submit from results tab, a whole table mission
            submission = resultsTable;
        }

        showSendingModal.checked = true;

        const { isSuccessful, response } = checkAnswer(submission);
        const solutionCRC = CRC(profile);

        Promise.all([
            waitForXSeconds(4),
            isSuccessful
                ? submitAnswer({ ...profile, solutionCRC, missionLog })
                : Promise.resolve()
        ])
            .then(([, updatedUser]) => {
                const audio = new Audio(
                    isSuccessful ? "sounds/stamp.mp3" : "sounds/thick_stamp.mp3"
                );
                audio.play();

                if (isSuccessful) {
                    updateProfile(updatedUser);
                    if (profile.rank !== profile.qualifiedRank) {
                        solvedMessage.innerHTML = `You qualify for the rank of<BR><span class="account-rank">${profile.qualifiedRank}!</span>`;
                        showTakeRankTest.checked = true;
                    } else {
                        solvedMessage.innerHTML = response;
                        showAdvanceToNextCase.checked = true;
                    }
                    showSolvedStamp.checked = true;
                } else {
                    retryMessage.innerHTML = response;
                    showRetryStamp.checked = true;
                }
            })
            .catch((e) => {
                if (e.message === "End of free trial") {
                    solvedMessage.innerHTML = `
                        - End of free trial -
                        <BR>
                        <BR>
                        <label class="link-like" for="show-buy-a-license">Buy a license</label> to
                        take the rank test and advance to the next level.
                    `;
                    showEndOfFreeTrial.checked = true;
                    showSolvedStamp.checked = true;
                } else {
                    retryMessage.innerHTML = e.message;
                    showRetryStamp.checked = true;
                }
            });
    } else if (
        classes.contains("advance-to-next-case") ||
        classes.contains("try-a-few-cases") ||
        classes.contains("logged-in-continue-cases") ||
        classes.contains("after-buy-solve-cases")
    ) {
        // Done in CSS for advance-to-next-case: showNoSendingModal.checked = true;

        if (profile.reachedDevelopmentLimit && !seenStillInDevelopmentMessage) {
            // TODO condition for still in development overlay
            showStillInDevelopment.checked = true;
            seenStillInDevelopmentMessage = true;
        }

        updateMission();
        showMissionTab.checked = true;
        showWorkspaceSection.checked = true;
    } else if (classes.contains("retry-case")) {
        // Done in CSS: showNoSendingModal.checked = true;
        showBuilderTab.checked = true;
    } else if (classes.contains("take-rank-test")) {
        // Done in CSS: showNoSendingModal.checked = true;
        updateRankTest();
        showRankTestSection.checked = true;
    } else if (classes.contains("rank-test-option")) {
        const testSolution = target.innerText;
        showRankTestSendingModal.checked = true;

        Promise.all([
            waitForXSeconds(4),
            submitTestSolution(profile.accessToken, testSolution)
        ]).then(([, updatedUser]) => {
            const isCorrect = updatedUser.rank === updatedUser.qualifiedRank;
            const reason = updatedUser.reason || encouragement();
            updateProfile(updatedUser);
            const audio = new Audio(
                isCorrect ? "sounds/clapping.mp3" : "sounds/thick_stamp.mp3"
            );
            audio.play();
            if (isCorrect) {
                showRankTestSuccess.checked = true;
                approveMessage.innerHTML = reason;
                approvedQualifiedNote.innerHTML = profile.rank;
                approvedRank.innerHTML = profile.rank;
            } else {
                declineMessage.innerHTML = reason;
                showRankTestFailure.checked = true;
            }
        });
    } else if (classes.contains("login-link")) {
        showLoggingIn.checked = true;
        return login();
    } else if (classes.contains("logout-link")) {
        showLoggingOut.checked = true;
        return logout();
    }
    queryAutocorrect();
});

const syncUser = async (user) => {
    const response = await getUser(user);
    [
        "rank",
        "solved",
        "missionAttributes",
        "casesLeftToNextRank",
        "missionSeed",
        "reachedDevelopmentLimit"
    ].forEach((field) => {
        user[field] = response[field];
    });
    user.ready = true;
};

function updateUIAndUser() {
    let user = getAuthUser();
    // user === null - No user logged in
    // user === undefined - In the process of getting user email from auth0
    // user === { email: '....' } - Got auth0 confirmation
    // user.ready === true - retrieved user information from sqlpd
    if (user === null) {
        // No user is the default state.
        // In case of logout the site will reload to this default state.
        return;
    }

    if (user === undefined || !user.ready) {
        showLoggingIn.checked = true;
        timesToShowInstructions = 2;
        if (user && user.email && user.accessToken) {
            syncUser(user).then(updateUIAndUser, (e) => {
                // Display errors in the console
                // TODO proper error handling
                console.error(e);
            });
        }
    }

    if (user && user.ready) {
        showLoggingIn.checked = false;
        showLogoutOptions.checked = true;
        showProfileLogoutOptions.checked = true;

        profile = user;
        accountEmailSpans.forEach((elem) => {
            elem.innerHTML = user.email;
        });
    }
}

window.onload = async () => initialiseAuth(updateUIAndUser);
reset();
